import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Logo } from '../styled';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-width: 20vmax;
  max-width: 20vmax;
  font-family: 'AvantGardeDemi';

  font-size: ${(props) => props.theme.fontSize.small};
  color: ${(props) => props.theme.colors.white};

  & > ul {
    display: inline-block;
    text-align: left;
    padding: 0;
    font-family: 'AvantGardeBook';
  }
`;

const Speaker = styled.img`
  height: 15vmax;
  width: 15vmax;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.white};
  object-fit: cover;
`;

const Name = styled.div`
  font-weight: bolder;
  padding: 1rem 0;
`;

export default ({ data }) => {
  const isNotBigScreen = useMediaQuery({
    maxDeviceWidth: 1100
  });
  return (
    <Container>
      <Speaker src={data.photo} alt={`${data.name}-photo`} />
      <Logo src={data.flag} alt={`${data.name}-flag`} flag isNotBigScreen={isNotBigScreen} />
      <Name>{data.name}</Name>
      <ul>
        {data.highlights.map((highlight) => (
          <li>{highlight}</li>
        ))}
      </ul>
    </Container>
  );
};
