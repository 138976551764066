import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import { Agenda } from '../Icons';
import { PopupContext } from '../../contexts';

export default () => {
  const { toogle } = useContext(PopupContext);
  return (
    <>
      <div data-tip data-for="agendaTip">
        <Agenda size="2x" onClick={toogle} />
      </div>
      <ReactTooltip id="agendaTip" place="top" effect="float">
        Ver actividades
      </ReactTooltip>
    </>
  );
};
