/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import * as Yup from 'yup';
import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import { toast } from 'react-toastify';

import { Column, Row } from '../../../components/Layout';
import { FormSubTitle } from './styled';
import { COLLECTIONS, VALIDATIONS } from '../../../constants';
import Button from '../../../components/Button';
import LoadingSpinner from '../../../components/LoadingSpinner';

const validationSchema = Yup.object().shape({
  speakers: Yup.array().of(
    Yup.object().shape({
      speaker: Yup.string().required(VALIDATIONS.FIELD_REQUIRED)
    })
  )
});

let docRef;
export default ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [oradores, setOradores] = useState([]);
  const firestore = useFirestore();

  useEffect(() => {
    const init = async () => {
      docRef = await firestore.collection(COLLECTIONS.ORADORES).doc(data.alias);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            const result = doc.data();
            setOradores({ speakers: result.speakers });
            setLoading(false);
          } else {
            setLoading(false);
            // doc.data() will be undefined in this case
            console.log('No such document!');
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log('Error getting document:', error);
        });
    };
    init();
  }, []);

  const submit = async (values) => {
    docRef
      .update({
        speakers: [...values.speakers]
      })
      .then(() => {
        toast.success('Guardado');
      });
  };

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? error : null;
      }}
    />
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Row>
        <FormSubTitle>Oradores</FormSubTitle>
      </Row>
      <Row>
        <Formik initialValues={oradores} validationSchema={validationSchema} onSubmit={submit}>
          {({ values }) => (
            <Form>
              <FieldArray
                name="speakers"
                render={(arrayHelpers) => (
                  <Column>
                    {values.speakers &&
                      values.speakers.map((speaker, index) => (
                        <Column key={index}>
                          <Row style={{ margin: '15px 0' }}>
                            <Field name={`speakers[${index}].speaker`} />

                            <button type="button" onClick={() => arrayHelpers.remove(index)}>
                              -
                            </button>
                          </Row>
                          <div style={{ color: 'red' }}>
                            <ErrorMessage name="speakers[0].speaker" />
                          </div>
                        </Column>
                      ))}
                    <Button
                      type="button"
                      onClick={() => arrayHelpers.push({ speaker: '' })}
                      style={{ marginTop: '20px' }}
                    >
                      Agregar Orador
                    </Button>
                  </Column>
                )}
              />
              {values.speakers && values.speakers.length > 0 && (
                <Row style={{ justifyContent: 'center', marginTop: '15px' }}>
                  <Button type="submit">Aceptar</Button>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      </Row>
    </>
  );
};
