import styled from 'styled-components';
import { DEVICE } from '../constants';

export default styled.button`
  min-width: 128px;
  min-height: 32px;
  border-radius: 10px;
  font-size: medium;
  padding: 10px;
  background-color: lightgray;
  color: black;

  @media ${DEVICE.mobileS} {
    min-height: 20px;
    font-size: small;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.primaryColor};
  }
`;
