/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Column, Row } from '../../components/Layout';

export const Container = styled(Column)`
  margin: 20px;
  background-color: darkgrey;
  overflow: scroll;
  min-height: 25vh;
  padding: 20px;
`;
