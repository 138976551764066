import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { Container, Logo } from '../styled';
import Speaker from './Speaker';
import logo from './img/head-icon.png';
import modLogo from './img/mod-icon.png';

import argFlag from './img/arg-flag.png';
import espFlag from './img/esp-flag.png';

import fussaroPhoto from './img/fussaro.jpg';
import lavowskyPhoto from './img/labovsky.jpg';
import regidorPhoto from './img/regidor.jpg';
import palaciosPhoto from './img/palacios.png';

const BACKGROUND_URL = require('./img/background.png');

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  min-height: 150vh;
`;

const Title = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 6vh;
  text-align: center;
  align-items: center;
  font-family: AvantGardeDemi;

  & > :last-child {
    display: flex;
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.colors.white};
    justify-content: center;
  }
`;

const SpeakersBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 5rem 2rem 0rem 2rem;
  margin-top: 2vh;
`;

const ModeradoresBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 8rem;
  margin-bottom: 2vmax;
`;

const Moderador = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
  max-width: 40vw;
  height: 10vmax;
  padding: 1rem;
  background-color: ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize.small};
  text-align: center;
  border-radius: 1rem;
`;

const Name = styled.p`
  font-family: 'AvantGardeDemi';
`;
const Charge = styled.p`
  font-family: 'AvantGardeBook';
  color: #c4a7bd;
`;
const speakers = [
  {
    flag: espFlag,
    photo: regidorPhoto,
    name: 'Dr. Pedro Antonio Regidor',
    highlights: [
      'Especialista en Ginecología y Obstetricia',
      'Director Médico de Exeltis Europa',
      'Investigador científico'
    ]
  },
  {
    flag: espFlag,
    photo: palaciosPhoto,
    name: 'Dr. Santiago Palacios',
    highlights: [
      'Director del Instituto Palacios y Medicina de la Mujer',
      'Investigador Principal de Slinda'
    ]
  },
  {
    flag: argFlag,
    photo: fussaroPhoto,
    name: 'Dr. David Fusaro',
    highlights: [
      'Ginecólogo',
      'Director médico del instituto Ginecólogico de Buenos Aires (IGBA)',
      'Investigador científico'
    ]
  },
  {
    flag: argFlag,
    photo: lavowskyPhoto,
    name: 'Dra. Marisa Labovsky',
    highlights: [
      'Ginecóloga especialista en Tocoginecología',
      'Ginecólogía endocrinológica e infanto juvenil'
    ]
  }
];

export default () => {
  const isNotBigScreen = useMediaQuery({
    maxDeviceWidth: 1350
  });
  return (
    <StyledContainer imageUrl={BACKGROUND_URL}>
      <Title>
        <Logo src={logo} alt="speakers-logo" isNotBigScreen={isNotBigScreen} />
        <div>Disertantes</div>
      </Title>
      <SpeakersBox>
        {speakers.map((speaker) => (
          <Speaker data={speaker} />
        ))}
      </SpeakersBox>
      <Title>
        <Logo src={modLogo} alt="mod-logo" isNotBigScreen={isNotBigScreen} />
        <div>Moderadores</div>
      </Title>
      <ModeradoresBox>
        <Moderador>
          <Name>Dr. Matías Deprati</Name>
          <Charge>Director de Asuntos Médicos Laboratorios Elea</Charge>
        </Moderador>
        <Moderador>
          <Name>Dr. Felipe Jofré</Name>
          <Charge>Asesor Médico Salud Femenina Laboratorios Elea</Charge>
        </Moderador>
      </ModeradoresBox>
    </StyledContainer>
  );
};
