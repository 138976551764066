import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { Question } from '../Icons';
import { QuestionContext } from '../../contexts';
import { SLINDA_URL } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-items: center;
  align-items: center;
`;

export default ({ alias }) => {
  const { toogle } = useContext(QuestionContext);

  if (alias === SLINDA_URL) {
    return (
      <Container onClick={toogle}>
        <Question size="2x" />
        <p>Click para hacer una pregunta</p>
      </Container>
    );
  }

  return (
    <>
      <div data-tip data-for="questionTip">
        <Question size="2x" onClick={toogle} />
      </div>
      <ReactTooltip id="questionTip" place="top" effect="float">
        Hacé una pregunta al moderador
      </ReactTooltip>
    </>
  );
};
