import styled from 'styled-components';

import { Row, Column } from '../Layout';
import { DEVICE } from '../../constants';

export const Wrapper = styled(Column)`
  border-radius: 10px;
`;

export const MessageInputWrapper = styled(Row)`
  position: sticky;
  width: 100%;
  background: white;
  border-radius: inherit;

  @media ${DEVICE.mobileS} {
    min-height: 30px;
    font-size: small;
    bottom: 0px;
  }

  @media ${DEVICE.tablet} {
    min-height: 40px;
    font-size: medium;
  }

  @media ${DEVICE.desktop} {
    min-height: 60px;
    font-size: large;
  }
`;

export const MessagesContainer = styled(Column)`
  height: 100%;
  overflow-y: scroll;
  padding: 0 10px;

  @media ${DEVICE.mobileS} {
    font-size: 0.6rem;
  }

  @media ${DEVICE.tablet} {
    margin: 0;
    font-size: 1rem;
  }
  @media ${DEVICE.laptop} {
    margin: 0;
    font-size: 1.2rem;
  }
`;

export const MessageContainer = styled(Row)`
  justify-content: ${(props) => (props.toRight ? 'flex-end' : 'flex-start')};
`;

export const ScrollableWrapper = styled(Column)`
  flex-direction: column;
  flex-grow: 1;
  overflow: visible;
  min-height: 0;
`;

export const PinnedMessage = styled(Row)`
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  box-sizing: content-box;
  background: white;
  word-break: break-word;
  margin-top: 10px;
  border-radius: 7px;
  z-index: 2;
`;

export const PinContainer = styled(Column)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transform: rotate(-25deg);
`;
