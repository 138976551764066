import React, { useState } from 'react';
import { Formik } from 'formik';
import { useFirestore } from 'react-redux-firebase';
import * as Yup from 'yup';

import { Column, Row } from '../../../components/Layout';
import { Confirm } from '../../../components/Icons';
import { COLLECTIONS, VALIDATIONS } from '../../../constants';
import { InputContainer, Input } from './styled';
import LoadingSpinner from '../../../components/LoadingSpinner';

const schema = Yup.object().shape({
  alias: Yup.string().required(VALIDATIONS.ALIAS_REQUIRED)
});

export default ({ docId, callback }) => {
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();

  const submit = (values, { resetForm }) => {
    setLoading(true);
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .doc(docId)
      .update({
        alias: values.alias
      })
      .then(() => {
        resetForm({});
        callback();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Formik initialValues={{ alias: '' }} validationSchema={schema} onSubmit={submit}>
      {({ values, errors, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <Column>
            <Row style={{ alignItems: 'center' }}>
              <InputContainer style={{ flex: 1 }}>
                <Input
                  type="text"
                  name="alias"
                  onChange={handleChange}
                  value={values.alias || ''}
                  style={{ width: '100%' }}
                />
              </InputContainer>
              <Confirm onClick={handleSubmit} size="2x" style={{ padding: '5px' }} />
              {loading && <LoadingSpinner />}
            </Row>
            {errors && errors.alias && (
              <p style={{ color: 'red', margin: '0', fontSize: 'small' }}>{errors.alias}</p>
            )}
          </Column>
        </form>
      )}
    </Formik>
  );
};
