/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext, useEffect } from 'react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../../components/Button';
import { COLLECTIONS, LOCAL_STORAGE } from '../../../constants';
import { UserContext } from '../../../contexts';
import useIsMobile from '../../../hooks/useIsMobile';
import { Container, Logo } from '../styled';

const BACKGROUND_URL = require('./img/background.png');
const TITLE = require('./img/background_title.png');
const GO_TO_STREAM = require('./img/head-icon.png');

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Header = styled.img`
  height: 18vmax;
`;

const GoToStream = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column;
  top: 3vmax;
  right: 3vmax;
  align-items: center;
  justify-content: center;
  font-family: 'AvantGardeBook';

  ${({ isMobile }) =>
    isMobile &&
    css`
      top: 3vmin;
      right: 3vmin;
    `}
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.kobi};
  color: ${(props) => props.theme.secondaryColor};
  border: none;
  cursor: pointer;
  min-width: auto;
  min-height: 1vmax;
  width: 15vmax;
  padding: 0.7vmax;
  font-size: ${(props) => props.theme.fontSize.small};
  border-radius: 2vmax;
  margin-top: 1vmax;
  font-weight: bold;
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 1vmax;
    `}
`;
let reportRef;

export default () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const userId = firebase.auth().currentUser.uid;
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      reportRef = await firestore.collection(COLLECTIONS.REPORTS).doc('slinda');
    };
    init();
  }, []);

  const handleRedirect = () => {
    history.push('/slinda/streaming');
    const loginId = uuidv4();
    sessionStorage.setItem(LOCAL_STORAGE.LOGIN_ID, loginId);
    reportRef.update({
      onlines: firestore.FieldValue.arrayUnion({
        loginId,
        userId,
        created: firestore.Timestamp.now(),
        ...user
      })
    });
  };

  return (
    <StyledContainer imageUrl={BACKGROUND_URL}>
      <Header src={TITLE} />
      <GoToStream isMobile={isMobile}>
        <Logo src={GO_TO_STREAM} />
        <StyledButton onClick={handleRedirect}>Ir a evento</StyledButton>
      </GoToStream>
    </StyledContainer>
  );
};
