import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Column, Row } from './Layout';
import { DEVICE } from '../constants';

const Container = styled(Column)`
  @media ${DEVICE.mobileS} {
    height: 100px;
    width: 100%;
    font-size: 0.6rem;
    background: white;
    overflow: overlay;
  }

  @media ${DEVICE.tablet} {
    height: 150px;
    width: 450px;
    background: white;
    overflow: overlay;
  }

  @media ${DEVICE.laptop} {
    height: 250px;
    font-size: 0.8rem;
    background: white;
    overflow: overlay;
  }
`;

const EntryRow = styled(Row)`
  text-align: center;
  @media ${DEVICE.tablet} {
    font-size: x-large;
    height: 80px;
    margin: 10px;
  }

  @media ${DEVICE.mobileS} {
    margin: 5px 0px;
  }
`;

const Time = styled(Row)`
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  font-weight: bolder;
  background-color: ${(props) => props.theme.colors.bigStone};
  color: ${(props) => props.theme.colors.white};
`;

const InfoColumn = styled(Column)`
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');
  color: ${(props) => props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.bigStone};
  font-family: 'Ubuntu', sans-serif;
  justify-content: center;

  @media ${DEVICE.tablet} {
    flex: 5;
  }

  @media ${DEVICE.mobileS} {
    flex: 3;
  }
`;

const InfoContent = styled(Column)`
  overflow-wrap: anywhere;
  letter-spacing: 1px;
`;

const Agenda = ({ agenda }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (agenda) {
      const formattedAgenda = agenda
        .map((el) => ({ ...el, start: el.start.toDate() }))
        .sort((a, b) => new Date(a.start) - new Date(b.start));
      setData(formattedAgenda);
    }
  }, [agenda]);

  return (
    <Container>
      <Row>
        <Column>
          {data.map(({ id, title, start }) => (
            <EntryRow key={id}>
              <Column style={{ flex: 1 }}>
                <Time>{moment(start).format('hh:mm A')}</Time>
              </Column>
              <InfoColumn>
                <Row style={{ alignItems: 'center' }}>
                  <InfoContent>
                    <p>{title}</p>
                  </InfoContent>
                </Row>
              </InfoColumn>
            </EntryRow>
          ))}
        </Column>
      </Row>
    </Container>
  );
};

export default React.memo(Agenda);
