/* eslint-disable no-confusing-arrow */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import { useFirestore } from 'react-redux-firebase';
import hash from 'object-hash';
import styled from 'styled-components';

import { StyledCalendar } from './styled';
import { COLLECTIONS } from '../../../constants';
import { Column } from '../../../components/Layout';
import Event from '../../../components/Event';

let docRef;
const Container = styled(Column)`
  width: 100%;
  height: 600px;
`;
const localizer = momentLocalizer(moment);

let newEvent;
const initialEvent = {
  id: 0,
  title: 'Evento de todo el dia',
  start: new Date(),
  end: new Date(),
  allDay: true
};

export default ({ id, events, callback }) => {
  const firestore = useFirestore();

  useEffect(() => {
    const init = () => {
      docRef = firestore.collection(COLLECTIONS.STREAMINGS).doc(id);
    };
    init();
  }, [firestore, id]);

  const handleSelect = ({ start, end }) => {
    const title = window.prompt('New Event name');
    newEvent = {
      start: firestore.Timestamp.fromDate(start),
      end: firestore.Timestamp.fromDate(end),
      title
    };

    docRef.update({
      agenda: firestore.FieldValue.arrayUnion({
        id: hash(Math.random()),
        parentId: id,
        ...newEvent
      })
    });
    callback();
  };

  return (
    <Container>
      <StyledCalendar
        selectable
        popup
        localizer={localizer}
        events={events || [initialEvent]}
        views={['day']}
        defaultView="day"
        defaultDate={events ? events[0].start : new Date()}
        scrollToTime={events ? events[0].start : null}
        onSelectSlot={handleSelect}
        components={{ toolbar: () => null, event: Event }}
      />
    </Container>
  );
};
