import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import styled from 'styled-components';

export default styled(StyledFirebaseAuth)`
  width: 100%;

  .firebaseui-container {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
  }

  .firebaseui-card-header {
    height: 0px;
    visibility: hidden;
  }

  .firebaseui-input {
    border-bottom: 1px solid white !important;
  }

  .firebaseui-button {
    width: 128px;
    height: 32px;
    border-radius: 10px;
    font-size: medium;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
