import React, { useState, useEffect } from 'react';
import { useFirebase } from 'react-redux-firebase';

import styled from 'styled-components';
import { Row } from '../Layout';
import { Users } from '../Icons';
import { DEVICE } from '../../constants';

const OnlineUsers = styled(Row)`
  position: absolute;
  top: 3%;
  left: 3%;
  padding: 0 3px;
  z-index: 2;
  color: white;
  align-items: center;
  background-color: black;
  box-shadow: 2px 2px 3px #999;

  @media ${DEVICE.mobileS} {
    font-size: 0.6rem;
  }

  @media ${DEVICE.tablet} {
    font-size: 0.9rem;
  }
`;

const UsersWrapper = styled.div`
  padding-right: 5px;
`;

export default () => {
  const [onlineUsers, setOnlineUsers] = useState();
  const firebase = useFirebase();

  useEffect(() => {
    const userListRef = firebase.database().ref('/presence');
    const thisUserRef = userListRef.push();

    // Monitor connection state on browser tab
    firebase
      .database()
      .ref('.info/connected')
      .on('value', (snap) => {
        if (snap.val()) {
          // if we lose network then remove this user from the list
          thisUserRef.onDisconnect().remove();
        }
      });

    userListRef.on('value', (snap) => {
      setOnlineUsers(snap.numChildren());
    });
  }, [firebase]);

  return (
    <OnlineUsers>
      <UsersWrapper>{onlineUsers}</UsersWrapper>
      <Users />
    </OnlineUsers>
  );
};
