/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import hash from 'object-hash';

import { Column, Row } from './Layout';
import Thumbnail from './Thumbnail';

export default ({ id, alias, folder, onFinish, collection }) => {
  const [isUploading, setIsUploading] = useState();
  const [progress, setProgress] = useState();
  const [avatarURL, setAvatarURL] = useState();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebase.auth);
  const baseUrl = `images/${auth.uid}/${folder}`;

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
  };

  const handleProgress = (percentaje) => setProgress(percentaje);
  const handleUploadError = () => {
    setIsUploading(false);
  };

  const handleUploadSuccess = (filename) => {
    firebase
      .storage()
      .ref(baseUrl)
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        firestore.collection(collection).add({
          id: hash(Math.random()),
          docId: id,
          alias,
          url,
          userId: auth.uid,
          created: new Date()
        });
        setAvatarURL(url);
        onFinish();
        setProgress(100);
        setIsUploading(false);
      });
  };

  return (
    <Column style={{ margin: '20px 0px' }}>
      <Row>
        <FileUploader
          accept="image/*"
          name="avatar"
          randomizeFilename
          storageRef={firebase.storage().ref(baseUrl)}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
        />
      </Row>
      {isUploading && <Row>{progress}</Row>}
      {avatarURL && (
        <Row>
          <Column>
            <Row>Image</Row>
            <Row>
              <Thumbnail src={avatarURL} alt="avatar" />
            </Row>
          </Column>
        </Row>
      )}
    </Column>
  );
};
