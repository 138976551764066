import React from 'react';
import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Column, Row } from '../../components/Layout';
import { DEVICE } from '../../constants';
import WorkSansRegular from './font/WorkSans-Regular.ttf';

export const InputContainer = styled(Column)`
  &:hover {
    cursor: pointer;
  }
  @media ${DEVICE.mobileS} {
    margin: 5px 0px;
  }

  @media ${DEVICE.tablet} {
    margin: 10px 0px;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: all 0.5s ease-in-out;

  ${InputContainer}:focus {
    top: -14px;
    font-size: 6px;
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.89);
  border-radius: 7px;
  z-index: 2;
  margin: 20px 0;

  @media ${DEVICE.mobileS} {
    min-width: 250px;
  }

  @media ${DEVICE.mobileL} {
    min-width: 300px;
  }

  @media ${DEVICE.laptop} {
    min-width: 450px;
  }

  ${({ tiendaDiggitEvent }) =>
    tiendaDiggitEvent &&
    css`
      @font-face {
        font-family: 'WorkSansRegular';
        src: url(${WorkSansRegular}) format('truetype');
      }
      font-family: WorkSansRegular;
      color: white;
      background: rgb(1, 134, 185);
      background: linear-gradient(90deg, rgba(1, 134, 185, 1) 0%, rgba(65, 162, 140, 1) 100%);

      & > div > form > div > h2 {
        margin-bottom: 2rem;
        margin-top: 0;
      }
      & > div > form > div > div > label {
        font-weight: normal;
      }

      & > div > form > div > div > input {
        border-bottom: 1px solid white;
      }

      & > div > form > div > div > div > div > label {
        font-weight: normal;
      }
      & > div > form > div > div > div > div > div > input {
        border-bottom: 1px solid white;
      }

      & > div > form > div > button {
        color: #2b989c;
        font-weight: bolder;
        font-size: large;
      }

      .flag-select__btn {
        color: white;
      }

      @media ${DEVICE.mobileS} {
        width: 250px;
        min-height: 250px;
      }

      @media ${DEVICE.tablet} {
        min-width: 450px;
        min-height: 400px;
        width: auto;
      }

      @media ${DEVICE.laptop} {
        min-width: 400px;
        min-height: 480px;
        margin-top: 12%;
      }

      @media ${DEVICE.laptopL} {
        min-width: 500px;
        min-height: 480px;
      }
    `}
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 7px;

  @media ${DEVICE.mobileS} {
    padding: 15px;
  }

  @media ${DEVICE.laptop} {
    padding: 25px;
  }
`;

export const Input = styled.input`
  border: 0;
  border-bottom: 2px solid #555;
  background: transparent;
  width: 100%;
  padding: 8px 0 5px 0;
  font-size: 16px;
  color: #fff;

  &:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid #e74c3c;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
    transition: 'color 9999s ease-out, background-color 9999s ease-out';
    transition-delay: 9999s;
  }

  &::-webkit-input-placeholder {
    color: grey;
  }
`;

export const Button = styled.button`
  width: 128px;
  height: 32px;
  border-radius: 10px;
  font-size: medium;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Error = styled.p`
  font-size: 12px;
  color: red;
  text-align: end;
`;

const LogoImage = styled(LazyLoadImage)`
  margin-bottom: ${(props) => props.first && '20px'};
`;

export const Logo = ({ id, first, url }) => (
  <LogoImage first={first} src={url} alt={`logo${id}`} effect="blur" />
);

export const LogoWrapper = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const CountdownWrapper = styled(Column)`
  color: white;
  align-items: center;
  justify-content: space-evenly;
  & > p {
    @media ${DEVICE.mobileS} {
      font-size: x-large;
    }

    @media ${DEVICE.tablet} {
      font-size: xx-large;
      padding: 25px;
    }
  }
`;

export const CountdownRow = styled(Row)`
  justify-content: center;
  color: white;
  font-weight: bolder;
  padding: 0 25px;

  ${({ title }) =>
    title &&
    css`
      @media ${DEVICE.mobileS} {
        font-size: 1rem;
      }

      @media ${DEVICE.tablet} {
        font-size: 2rem;
      }
    `}

  ${({ value }) =>
    value &&
    css`
      @media ${DEVICE.mobileS} {
        font-size: 3rem;
      }

      @media ${DEVICE.tablet} {
        font-size: 5rem;
      }

      @media ${DEVICE.laptop} {
        font-size: 8rem;
      }
    `}
`;

export const ButtonRow = styled(Row)`
  justify-content: center;

  @media ${DEVICE.mobileS} {
    margin-top: 15px;
  }

  @media ${DEVICE.laptop} {
    margin-top: 20px;
  }
`;
