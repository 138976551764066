/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useLocation } from 'react-router';

import { COLLECTIONS } from '../../constants';
import Moderador from '.';

export default () => {
  const location = useLocation();
  const alias = location.pathname.split('/')[1];
  const firestore = useFirestore();

  const getMessagesFromState = (state) =>
    state.firestore.ordered[COLLECTIONS.MODERATE_CHAT] &&
    state.firestore.ordered[COLLECTIONS.MODERATE_CHAT][0] &&
    state.firestore.ordered[COLLECTIONS.MODERATE_CHAT][0].messages
      ? state.firestore.ordered[COLLECTIONS.MODERATE_CHAT][0].messages
      : [];

  useFirestoreConnect(() => [{ collection: COLLECTIONS.MODERATE_CHAT, doc: alias }]);
  const entries = useSelector((state) =>
    getMessagesFromState(state)
      .map((message) => ({ ...message, created: message.created.toDate() }))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
  );

  const handleClick = (values) => {
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .doc(alias)
      .update({
        messages: firestore.FieldValue.arrayUnion({
          id: values.id,
          created: values.created,
          text: values.message,
          fullName: values.user
        })
      })
      .then(() => {
        const futureChat = entries.map((entry) => {
          if (entry.id === values.id) {
            return {
              id: values.id,
              created: values.created,
              text: values.message,
              fullName: values.user,
              approved: true
            };
          }
          return entry;
        });
        firestore.collection(COLLECTIONS.MODERATE_CHAT).doc(alias).update({ messages: futureChat });
      });
  };

  return (
    <Moderador
      entries={entries.map((entry) => ({
        id: entry.id,
        user: entry.userName || entry.fullName,
        message: entry.text,
        created: entry.created,
        approved: entry.approved
      }))}
      onApprove={handleClick}
      modulo="Chat"
    />
  );
};
