import React, { useState, useContext } from 'react';

import LoadingSpinner from '../../components/LoadingSpinner';

import Banner from './Banner';
import SideChat from './SideChat';

import Player from '../../components/Player';
import Controls from '../../components/Controls';
import Popup from '../../components/Popup';
import Activities from '../../components/Activities';
import { PageCenteredContent } from '../../components/Layout';

import {
  PopupContext,
  PlayerContext,
  SoundContext,
  UserContext,
  PinnedMessageContext,
  QuestionContext,
  CurrentPlayingLink
} from '../../contexts';
import { Section, Container } from './styled';

import useStreamingData from '../../hooks/useStreamingData';
import useBanner from '../../hooks/useBanner';
import useStreamBackground from '../../hooks/useStreamBackground';
import MakeAQuestion from '../../components/MakeAQuestion';
import MultiRoom from './MultiRoom';
import { SLINDA_URL } from '../../constants';
import SlindaCountdown from '../Slinda/SlindaCountdown';
import useIsMobile from '../../hooks/useIsMobile';
// import data from './mockData.json';

const slindaMobile = require('../Slinda/SlindaCountdown/img/background_mobile.png');

export default () => {
  const data = useStreamingData();
  const {
    user: { urlAlias }
  } = useContext(UserContext);
  const banner = useBanner(urlAlias);

  const {
    hideChat,
    alias,
    agenda,
    link,
    showLiveTime,
    hasTrivia,
    hasContest,
    pinnedMessage,
    hasQuestion,
    demoEvent,
    multiVideo
  } = data || {};
  const [popup, setPopup] = useState(false);
  const [question, setQuestion] = useState(false);
  const isMobile = useIsMobile();

  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [currentPlayingLink, setCurrentPlayingLink] = useState(link);
  const { loadingStreamBackground, streamBackground } = useStreamBackground(alias);

  const tooglePopup = () => setPopup(!popup);
  const toogleQuestion = () => setQuestion(!question);

  const tooglePlay = (value) => setIsPlaying(value);
  const toogleMuted = (value) => setIsMuted(value);

  if (!data && !loadingStreamBackground) {
    return (
      <PageCenteredContent>
        <LoadingSpinner />
      </PageCenteredContent>
    );
  }

  const getBackground = () => {
    if (alias === SLINDA_URL) {
      if (isMobile) {
        return null;
      }
    }

    return streamBackground && streamBackground.url;
  };

  return (
    <QuestionContext.Provider value={{ toogle: toogleQuestion }}>
      <PopupContext.Provider value={{ toogle: tooglePopup }}>
        <PlayerContext.Provider value={{ isPlaying, tooglePlay }}>
          <SoundContext.Provider value={{ isMuted, setMuted: toogleMuted }}>
            <PinnedMessageContext.Provider value={{ pinnedMessage }}>
              <CurrentPlayingLink.Provider value={{ currentPlayingLink, setCurrentPlayingLink }}>
                <Container background={getBackground()} style={{ backgroundColor: '#681d55' }}>
                  <Section player>
                    {false ? (
                      <>
                        {isMobile && (
                          <img
                            src={slindaMobile}
                            style={{ position: 'absolute', height: '20vh', top: '3vh' }}
                            alt="mobile-logo"
                          />
                        )}
                        <SlindaCountdown />
                      </>
                    ) : (
                      <Player
                        alias={alias}
                        link={currentPlayingLink || link}
                        showLiveTime={showLiveTime}
                        hasBanner={banner}
                      />
                    )}
                  </Section>
                  {banner && (
                    <Section banner>
                      <Banner src={banner && banner.url} />
                    </Section>
                  )}
                  <Section controls multiVideo={multiVideo}>
                    <Controls
                      hasQuestion={hasQuestion}
                      hasTrivia={hasTrivia}
                      hasContest={hasContest}
                      demoEvent={demoEvent}
                      alias={alias}
                      hasAgenda={agenda && agenda.length > 0}
                    />
                    {multiVideo && <MultiRoom data={multiVideo} />}
                  </Section>
                  {!hideChat && <SideChat alias={alias} />}

                  <Popup
                    visible={popup && !question}
                    showCloseIcon={false}
                    onClose={() => setPopup(false)}
                  >
                    {agenda && <Activities agenda={agenda} />}
                  </Popup>
                  <Popup
                    visible={question && !popup}
                    showCloseIcon={false}
                    onClose={() => setQuestion(false)}
                  >
                    <MakeAQuestion />
                  </Popup>
                </Container>
              </CurrentPlayingLink.Provider>
            </PinnedMessageContext.Provider>
          </SoundContext.Provider>
        </PlayerContext.Provider>
      </PopupContext.Provider>
    </QuestionContext.Provider>
  );
};
