import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from 'react-responsive';

import { Column, Row } from '../../components/Layout';

import MailInput from './MailInput';

const PageContainer = styled.div`
  display: flex;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  color: white;
`;

/* Modal Content/Box */
const Page = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
`;

const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(31, 32, 54);
  opacity: 0.9;
  margin-top: auto;
  align-items: center;
  justify-content: space-around;
  padding: 0px 30px 15px 30px;
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  align-items: center;
  font-size: 36px;
`;

/*
const video = require('../../assets/video.mp4');
<ReactPlayer url={video} height="auto" width="auto" muted playing loop />
*/
const image = require('../../assets/avlogo.png');

export default () => {
  const mobile = useMediaQuery({ maxWidth: 425 });
  return (
    <PageContainer>
      <Page>
        <Row style={{ justifyContent: 'center' }}>
          <TitleColumn>
            <LazyLoadImage src={image} width="350px" effect="blur" />
            <div>Streaming Website</div>
          </TitleColumn>
        </Row>
        <Footer>
          <Column style={{ alignItems: 'center' }}>
            {!mobile && <h1>Estemos en contacto</h1>}
            <h2>Envianos tu correo, nosotros te contactamos</h2>
          </Column>
          <Column>
            <MailInput />
          </Column>
        </Footer>
      </Page>
    </PageContainer>
  );
};
