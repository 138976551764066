/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import hash from 'object-hash';
import { useSelector } from 'react-redux';

import { Exclamation } from '../../../components/Icons';
import { Row, Column } from '../../../components/Layout';
import Button from '../../../components/Button';
import { ROUTES, COLLECTIONS, EMAIL_ADMIN } from '../../../constants';
import { ShowColor } from '../styled';
import LoadingSpinner from '../../../components/LoadingSpinner';

const EditButton = ({ row }) => {
  const history = useHistory();
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Button
        onClick={() =>
          auth.email === EMAIL_ADMIN
            ? history.push(`${ROUTES.EDIT}/${row.original.id}`)
            : history.push(`${ROUTES.TECNICA}/${row.original.id}`)
        }
      >
        Editar
      </Button>
    </Row>
  );
};

const GeneratePasswordButton = ({ row }) => {
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();

  const handleAdd = () => {
    setLoading(true);
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .doc(row.original.docId)
      .update({
        sharedPassword: hash(Math.random())
      })
      .then(() => {
        setLoading(false);
        window.location.reload();
      });
  };

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Button onClick={handleAdd}>Generar password</Button>
      {loading && (
        <div style={{ marginLeft: '10px' }}>
          <LoadingSpinner />
        </div>
      )}
    </Row>
  );
};

const isValid = ({ original: values }) => values.link;

export default [
  {
    Header: 'Alias',
    accessor: 'alias'
  },
  {
    Header: 'Link',
    accessor: 'link',
    Cell: ({ row }) => (
      <Row style={{ justifyContent: 'center' }}>
        {row.values.link || (
          <>
            <Exclamation color="red" style={{ marginRight: '15px' }} />
            Completar campos requeridos
          </>
        )}
      </Row>
    )
  },
  {
    Header: 'Colores',
    accessor: 'colors',
    Cell: ({ row }) => (
      <Row style={{ justifyContent: 'center' }}>
        {row.values.colors ? (
          <>
            <Column style={{ alignItems: 'center' }}>
              Primario
              <ShowColor color={row.values.colors.primary} />
            </Column>
            <Column style={{ alignItems: 'center' }}>
              Secundario
              <ShowColor color={row.values.colors.secondary} />
            </Column>
          </>
        ) : null}
      </Row>
    )
  },
  {
    Header: () => 'Agenda',
    accessor: 'agenda.id',
    Cell: ({ row }) => (
      <Column style={{ height: '65px', flex: 1 }}>
        {row.original.agenda && row.original.agenda.length > 0 ? (
          <Row style={{ flex: '1 1 auto', overflowY: 'auto', minHeight: '0px' }}>
            <Column>
              {row.original.agenda.map((event, i) => (
                <Row key={i} style={{ width: '100%', placeContent: 'center', alignSelf: 'center' }}>
                  <Column>{event.title}</Column>
                  <Column>{`${event.start} - ${event.end}`}</Column>
                </Row>
              ))}
            </Column>
          </Row>
        ) : (
          <>
            <Row
              style={{
                flex: '1 1 auto',
                overflowY: 'auto',
                minHeight: '0px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              -
            </Row>
          </>
        )}
      </Column>
    )
  },
  {
    Header: 'Password compartido',
    accessor: 'sharedPassword',
    Cell: ({ row }) => (
      <Row style={{ justifyContent: 'center' }}>
        {!isValid(row) ? (
          <>
            <Exclamation color="red" style={{ marginRight: '15px' }} />
            Completar campos requeridos
          </>
        ) : row.original.sharedPassword ? (
          <p>{row.original.sharedPassword}</p>
        ) : (
          <GeneratePasswordButton row={row} />
        )}
      </Row>
    )
  },
  {
    Header: 'Acciones',
    Cell: ({ row }) => <EditButton row={row} />
  }
];
