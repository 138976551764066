import React, { useState, useCallback, useEffect } from 'react';
import { useSpring } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import ReactTooltip from 'react-tooltip';

import {
  ContentWrapper,
  CollapsableContent,
  CollapseWrapper,
  CollapsableContentFixed,
  CollapseWrapperFixed
} from './styled';
import Chat from '../../components/Chat';
import { ArrowLeft, ArrowUp, ChatIcon } from '../../components/Icons';
import { IsEmojiBoxOpen } from '../../contexts';
import { NEW_SAP_EVENT } from '../../constants';

export default ({ alias }) => {
  const [show, setShow] = useState(false);
  const [emojiBoxOpen, setEmojiBoxOpen] = useState();

  useEffect(() => {
    if (alias === NEW_SAP_EVENT) {
      setShow(true);
    }
  }, [alias]);

  const isMobile = useMediaQuery({
    minDeviceWidth: 319,
    maxDeviceWidth: 767
  });

  const isTablet = useMediaQuery({
    minDeviceWidth: 768,
    maxDeviceWidth: 1024
  });

  const isDesktop = useMediaQuery({
    minDeviceWidth: 2560
  });

  const getWidthTouse = useCallback(() => {
    if (isMobile) return 250;
    if (isTablet) return 350;
    if (isDesktop) return 600;
    return 350;
  }, [isMobile, isTablet, isDesktop]);

  const animatedProps = useSpring({ width: show ? getWidthTouse() : 0 });
  const toogleCollapsable = () => setShow(!show);
  const toogleEmojiBox = () => setEmojiBoxOpen(!emojiBoxOpen);

  if (alias === NEW_SAP_EVENT) {
    return show ? (
      <IsEmojiBoxOpen.Provider value={{ value: emojiBoxOpen, tooglePicker: toogleEmojiBox }}>
        {!emojiBoxOpen && (
          <CollapseWrapperFixed showCollapsable={show} onClick={toogleCollapsable}>
            <ArrowUp />
          </CollapseWrapperFixed>
        )}
        <CollapsableContentFixed width={260}>
          <ContentWrapper>
            <Chat />
          </ContentWrapper>
        </CollapsableContentFixed>
      </IsEmojiBoxOpen.Provider>
    ) : (
      <CollapseWrapperFixed showCollapsable={show} onClick={toogleCollapsable}>
        <div data-tip data-for="chattip">
          <ChatIcon size="2x" />
        </div>
        <ReactTooltip id="chattip" place="bottom" effect="float">
          Abrir chat
        </ReactTooltip>
      </CollapseWrapperFixed>
    );
  }
  return show ? (
    <IsEmojiBoxOpen.Provider value={{ value: emojiBoxOpen, tooglePicker: toogleEmojiBox }}>
      <CollapsableContent style={animatedProps}>
        <ContentWrapper>
          <Chat width={getWidthTouse()} animatedProps={animatedProps} />
        </ContentWrapper>
        {!emojiBoxOpen && (
          <CollapseWrapper showCollapsable={show} onClick={toogleCollapsable}>
            <ArrowLeft />
          </CollapseWrapper>
        )}
      </CollapsableContent>
    </IsEmojiBoxOpen.Provider>
  ) : (
    <CollapseWrapper showCollapsable={show} onClick={toogleCollapsable}>
      <div data-tip data-for="chattip">
        <ChatIcon size="2x" />
      </div>
      <ReactTooltip id="chattip" place="right" effect="float">
        Abrir chat
      </ReactTooltip>
    </CollapseWrapper>
  );
};
