/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useFirestore } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import styled, { css } from 'styled-components';
import Countdown from 'react-countdown';
import { useMediaQuery } from 'react-responsive';
import hash from 'object-hash';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { COLLECTIONS, SLINDA_URL, VALIDATIONS } from '../../../constants';
import { ButtonRow, Error, FormWrapper, InputContainer, ThanksRegisterMessage } from './styled';
import { Row } from '../../../components/Layout';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { Container, StyledButton } from '../styled';

const BACKGROUND_URL = require('./img/background.png');
const LOGO_URL = require('./img/logo.png');

const preRegisterSchema = Yup.object().shape({
  name: Yup.string().required('Nombre requerido'),
  lastName: Yup.string().required('Apellido requerido'),
  email: Yup.string().email(VALIDATIONS.EMAIL_FORMAT).required(VALIDATIONS.EMAIL_REQUIRED),
  registrationId: Yup.string().required('Matrícula requerida'),
  state: Yup.string().required('Localidad requerida'),
  province: Yup.string().required('Provincia requerida')
});

const microLoginSchema = Yup.object().shape({
  email: Yup.string().email(VALIDATIONS.EMAIL_FORMAT).required(VALIDATIONS.EMAIL_REQUIRED)
});

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER
});

let preRegisterRef;

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  ${({ isNotBigScreen }) =>
    isNotBigScreen &&
    css`
      background-color: ${(props) => props.theme.secondaryColor};
      align-items: center;
      justify-content: center;
    `}
`;

const StyledInput = styled.input`
  border: 0;
  background: transparent;
  width: 100%;
  padding: 8px 0 5px 0;
  font-size: ${(props) => props.theme.fontSize.small};
  color: #fff;
  border-bottom: 2px solid ${(props) => props.theme.primaryColor};

  &:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid ${(props) => props.theme.mulberry};
  }

  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const FormContainer = styled(Row)`
  width: 30vw;
  border-radius: 2rem;
  margin-bottom: 2rem;

  ${({ isNotBigScreen }) =>
    isNotBigScreen &&
    css`
      position: flex;
      width: auto;
      top: auto;
      left: auto;
      background-color: ${(props) => props.theme.colors.mulberry};
    `}
`;

const CountdownWrapper = styled.div`
  width: 25vw;
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize.small};
  background-color: ${(props) => props.theme.colors.mulberry};
  border-radius: 2rem;
  padding: 1rem;

  & > div {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin: 0.5rem 0;
  }

  & > :last-child {
    color: ${(props) => props.theme.secondaryColor};
  }

  & > div > div {
    text-align: center;
    margin: 0 1rem;
  }

  & > div > div > first-child {
    font-weight: bolder;
  }

  ${({ isNotBigScreen }) =>
    isNotBigScreen &&
    css`
      width: auto;
      font-size: ${(props) => props.theme.fontSize.medium};
    `}
`;

const Content = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  flex-flow: column;
  align-items: center;
  top: 30vh;
  left: 5vw;
  ${({ isNotBigScreen }) =>
    isNotBigScreen &&
    css`
      width: auto;
      top: auto;
      left: auto;
      align-items: space-around;
    `}
`;

const MicroMenuQuestion = styled.div`
  display: flex;
  margin-bottom: 3vh;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize.micro};
  align-items: center;

  & > span {
    margin: 0;
    margin-left: 0.5rem;
  }

  & > * {
    cursor: pointer;
  }

  & > *:hover {
    text-decoration: underline;
  }
`;

const Terms = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 3vh;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize.micro};
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  & > div > p {
    color: ${(props) => props.theme.colors.mulberry};
  }

  & > div > input {
    margin: 0;
    padding: 1rem;
    margin-right: 1rem;
    border-radius: 1rem;
  }

  & > div > p {
    margin: 0;
  }
`;

const ErrorTerms = styled(Error)`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export default ({ onRegistrationSuccess }) => {
  const [showAcceptTermsError, setShowAcceptTermsError] = useState(false);
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [redirectToRegister, setRedirectToRegister] = useState(false);
  const [preRegisterInProgress, setPreRegisterInProgress] = useState();
  const [preRegisterFinish, setPreRegisterFinish] = useState();
  const [microLogin, setMicroLogin] = useState(false);
  const [userEmail, setUserEmail] = useState();

  const firestore = useFirestore();
  const alias = SLINDA_URL;
  const isNotBigScreen = useMediaQuery({
    maxDeviceWidth: 1350
  });
  const history = useHistory();
  useEffect(() => {
    const init = async () => {
      preRegisterRef = await firestore.collection(COLLECTIONS.PRE_REGISTER).doc(`${alias}`);
    };
    init();
  }, [alias, firestore]);

  useEffect(() => {
    if (preRegisterFinish) {
      setTimeout(() => {
        onRegistrationSuccess(userEmail);
      }, 3000);
    }

    if (redirectToRegister) {
      setTimeout(() => {
        history.push('/temp');
        history.goBack();
        setMicroLogin(false);
        setLoading(false);
        setShowError(false);
      }, 3000);
    }
  }, [preRegisterFinish, redirectToRegister]);

  const toogleTerms = () => setTerms(!terms);

  const submitPreregister = (values, { resetForm }) => {
    if (!terms) {
      setShowAcceptTermsError(true);
      return;
    }
    preRegisterRef
      .update({
        registered: firestore.FieldValue.arrayUnion({
          id: hash(Math.random()),
          created: new Date(),
          ...values
        })
      })
      .then(() => {
        setPreRegisterFinish('Gracias por registrarte,');
        setPreRegisterInProgress(false);
      })
      .catch(() => {
        toast.error('Ocurrio un error');
        setPreRegisterInProgress(false);
      })
      .finally(() => resetForm());
  };

  const submitMicroLogin = (values) => {
    setLoading(true);
    firestore
      .collection(COLLECTIONS.PRE_REGISTER)
      .where('alias', '==', alias)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          const data = doc.data();
          const emailFound = data.registered.some(
            (registered) => registered.email === values.email
          );
          if (emailFound) {
            setUserEmail(values.email);
            setPreRegisterFinish(' ');
            setLoading(false);
            return;
          }
          setShowError(true);
          setRedirectToRegister(true);
        });
      });
  };

  const showMicroLogin = () => setMicroLogin(true);

  const renderer = ({ days, hours, minutes, seconds }) => (
    <CountdownWrapper isNotBigScreen={isNotBigScreen}>
      <div>
        <div>
          <div>{days}</div>
          <div>Días</div>
        </div>
        <div>
          <div>{hours}</div>
          <div>Horas</div>
        </div>
        <div>
          <div>{minutes}</div>
          <div>Minutos</div>
        </div>
        <div>
          <div>{seconds}</div>
          <div>Segundos</div>
        </div>
      </div>
      <div>Encuentro con los expertos</div>
    </CountdownWrapper>
  );

  return (
    <>
      {Boolean(preRegisterFinish) && (
        <ThanksRegisterMessage>
          <p>{preRegisterFinish}</p>
          <p>Redireccionando...</p>
          <LoadingSpinner loading />
        </ThanksRegisterMessage>
      )}

      <StyledContainer imageUrl={!isNotBigScreen && BACKGROUND_URL} isNotBigScreen={isNotBigScreen}>
        {isNotBigScreen && (
          <img src={LOGO_URL} alt="logo" style={{ width: '30vmax', marginBottom: '2vmax' }} />
        )}
        <Content isNotBigScreen={isNotBigScreen}>
          {microLogin ? (
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={microLoginSchema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={submitMicroLogin}
            >
              {({ errors, handleSubmit, handleChange }) => (
                <FormContainer isNotBigScreen={isNotBigScreen}>
                  <FormWrapper onSubmit={handleSubmit}>
                    <Row>
                      <InputContainer>
                        <StyledInput name="email" onChange={handleChange} placeholder="Email" />
                        {errors && errors.email && <Error>{errors.email}</Error>}
                      </InputContainer>
                    </Row>
                    {showError && (
                      <Row>
                        <Error>El correo no se encuentra registrado</Error>
                      </Row>
                    )}
                    <ButtonRow>
                      {loading ? (
                        <LoadingSpinner loading />
                      ) : (
                        <StyledButton type="submit" isNotBigScreen={isNotBigScreen}>
                          Ingresar
                        </StyledButton>
                      )}
                    </ButtonRow>
                  </FormWrapper>
                </FormContainer>
              )}
            </Formik>
          ) : (
            <>
              <Formik
                initialValues={{
                  name: '',
                  lastName: '',
                  registrationId: '',
                  email: '',
                  state: '',
                  province: ''
                }}
                validationSchema={preRegisterSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={submitPreregister}
              >
                {({ errors, handleSubmit, handleChange }) => (
                  <FormContainer isNotBigScreen={isNotBigScreen}>
                    {preRegisterInProgress ? (
                      <LoadingSpinner size="84px" />
                    ) : (
                      <FormWrapper onSubmit={handleSubmit}>
                        <Row>
                          <InputContainer style={{ marginRight: '2vw' }}>
                            <StyledInput name="name" onChange={handleChange} placeholder="Nombre" />
                            {errors && errors.name && <Error>{errors.name}</Error>}
                          </InputContainer>
                          <InputContainer>
                            <StyledInput
                              name="lastName"
                              onChange={handleChange}
                              placeholder="Apellido"
                            />
                            {errors && errors.lastName && <Error>{errors.lastName}</Error>}
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <StyledInput
                              name="registrationId"
                              onChange={handleChange}
                              placeholder="Matrícula"
                            />
                            {errors && errors.registrationId && (
                              <Error>{errors.registrationId}</Error>
                            )}
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <StyledInput name="email" onChange={handleChange} placeholder="Email" />
                            {errors && errors.email && <Error>{errors.email}</Error>}
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <StyledInput
                              name="state"
                              onChange={handleChange}
                              placeholder="Localidad"
                            />
                            {errors && errors.state && <Error>{errors.state}</Error>}
                          </InputContainer>
                        </Row>
                        <Row>
                          <InputContainer>
                            <StyledInput
                              name="province"
                              onChange={handleChange}
                              placeholder="Provincia"
                            />
                            {errors && errors.province && <Error>{errors.province}</Error>}
                          </InputContainer>
                        </Row>
                        <ButtonRow>
                          <StyledButton type="submit" isNotBigScreen={isNotBigScreen}>
                            Registrarse
                          </StyledButton>
                        </ButtonRow>
                        {showAcceptTermsError && (
                          <ErrorTerms>Debe Aceptar los términos y condiciones</ErrorTerms>
                        )}
                      </FormWrapper>
                    )}
                  </FormContainer>
                )}
              </Formik>
              <MicroMenuQuestion>
                Si ya esta registrado haga click <span onClick={showMicroLogin}>AQUÍ</span>
              </MicroMenuQuestion>
              <Terms isNotBigScreen={isNotBigScreen}>
                <div>
                  <input type="checkbox" onClick={toogleTerms} />
                  <p>Acepto políticas de privacidad de datos</p>
                </div>

                <Link
                  to="/files/Politica-de-Protección-de-datos-personales.pdf"
                  target="_blank"
                  download
                >
                  <div style={{ color: 'white' }}>Ver políticas de privacidad de datos</div>
                </Link>
              </Terms>
            </>
          )}
          <Countdown date={moment('2021-06-09 19:00:00').toISOString()} renderer={renderer} />
        </Content>
      </StyledContainer>
    </>
  );
};
