import React from 'react';
import { ThemeProvider } from 'styled-components';

const whiteColor = 'white';
const tawnyPort = '#681d55';

const theme = {
  primaryColor: whiteColor,
  secondaryColor: tawnyPort,
  colors: {
    black: '#000000',
    orangePeel: '#FF9800',
    aliceblue: 'aliceblue',
    mulberry: '#c8578e',
    white: whiteColor,
    kobi: '#EDB4D3'
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSize: {
    micro: '1vmax',
    small: '1.2vmax',
    medium: '2vmax',
    large: '3.5vmax',
    huge: '6vmax'
  }
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
