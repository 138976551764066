import { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { COLLECTIONS } from '../constants';
import useUrlAlias from './useUrlAlias';

export default (alias) => {
  const [loading, setLoading] = useState(true);
  const [startAt, setStartAt] = useState(null);

  const firestore = useFirestore();
  const aliasToUse = alias || useUrlAlias();

  useEffect(() => {
    firestore
      .collection(COLLECTIONS.COUNTDOWNS)
      .where('alias', '==', aliasToUse)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          const data = doc.data();
          setStartAt(data.startAt);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [firestore, aliasToUse]);

  return { loadingCountdown: loading, startAt };
};
