/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useLocation } from 'react-router';
import Moderador from '.';
import { COLLECTIONS } from '../../constants';

export default () => {
  const location = useLocation();
  const alias = location.pathname.split('/')[1];
  const firestore = useFirestore();

  const getQuestionsFromState = (state) =>
    state.firestore.ordered[COLLECTIONS.MONITOR] &&
    state.firestore.ordered[COLLECTIONS.MONITOR][0] &&
    state.firestore.ordered[COLLECTIONS.MONITOR][0].questions
      ? state.firestore.ordered[COLLECTIONS.MONITOR][0].questions
      : [];

  const getSpeakersFromState = (state) =>
    state.firestore.ordered[COLLECTIONS.MONITOR] &&
    state.firestore.ordered[COLLECTIONS.MONITOR][0] &&
    state.firestore.ordered[COLLECTIONS.MONITOR][0].speakers
      ? state.firestore.ordered[COLLECTIONS.MONITOR][0].speakers
      : [];
  useFirestoreConnect(() => [{ collection: COLLECTIONS.MONITOR, doc: alias }]);
  const entries = useSelector((state) =>
    getQuestionsFromState(state)
      .map((message) => ({ ...message, created: message.created.toDate() }))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
  );

  const speakers = useSelector((state) => getSpeakersFromState(state));

  const handleClick = (values) => {
    const question = { ...values };
    firestore
      .collection(COLLECTIONS.QUESTIONS)
      .doc(alias)
      .update({
        questions: firestore.FieldValue.arrayUnion(question)
      })
      .then(() => {
        const futureQuestions = entries.map((entry) => {
          if (entry.id === values.id) {
            return {
              ...values,
              approved: true
            };
          }
          return entry;
        });
        firestore.collection(COLLECTIONS.MONITOR).doc(alias).update({ questions: futureQuestions });
      });
  };
  console.log('Entries: ', entries);
  return (
    <Moderador entries={entries} speakers={speakers} onApprove={handleClick} modulo="Preguntas" />
  );
};
