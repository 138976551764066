import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFirestore } from 'react-redux-firebase';

import { Row } from './Layout';
import { COLLECTIONS } from '../constants';

let docRef;

const DeleteEvent = styled.div`
  position: absolute;
  right: 0;
  margin-right: 15px;
`;

export default ({ event }) => {
  const firestore = useFirestore();

  useEffect(() => {
    const init = () => {
      if (event.parentId) {
        docRef = firestore.collection(COLLECTIONS.STREAMINGS).doc(event.parentId);
      }
    };
    init();
  }, [firestore, event.parentId]);

  const handleDelete = () => {
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { agenda } = doc.data();
          const newAgenda = agenda.filter((el) => el.id !== event.id);
          docRef.update({
            agenda: newAgenda
          });
          window.location.reload();
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  };

  return (
    <Row>
      {event.title}
      <DeleteEvent onClick={handleDelete}>X</DeleteEvent>
    </Row>
  );
};
