import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import styled from 'styled-components';

import { PageCenteredContent, WelcomePage } from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner';
import { SAP_URL } from '../../constants';
import useBackground from '../../hooks/useBackground';
import useIsMobile from '../../hooks/useIsMobile';
import useUpperLogo from '../../hooks/useUpperLogo';
import { BottomLogo, LoginWrapper, Logo, LogoWrapper } from './styled';
import BentonSansTTF from './font/BentonSans-Regular.ttf';

const IMAGE_URL = require('../../assets/welcome.png');
const SAP_LOGO = require('./img/sap.png');
const SNP_LOGO = require('./img/snp.png');

export const StyledSapWelcome = styled(WelcomePage)`
  overflow: hidden;
  @font-face {
    font-family: 'BentonSans';
    src: url(${BentonSansTTF}) format('truetype');
  }
  font-family: BentonSans;
`;

export default ({ loadingFrame, extraMargin, children }) => {
  const isMobile = useIsMobile();
  const alias = SAP_URL;
  const { loadingUpperLogo, upperLogo } = useUpperLogo(alias);
  const { loadingBackground, background } = useBackground(alias);

  if (loadingBackground || loadingUpperLogo) {
    return (
      <LazyLoadComponent>
        <PageCenteredContent
          withBackgroundImage={alias === 'welcome' ? IMAGE_URL : background && background.url}
        >
          <LoadingSpinner />
        </PageCenteredContent>
      </LazyLoadComponent>
    );
  }
  return (
    <StyledSapWelcome
      style={{ justifyContent: 'center' }}
      isMobile={isMobile}
      withBackgroundImage={(background && background.url) || IMAGE_URL}
    >
      {upperLogo && (
        <LogoWrapper>
          <Logo key={upperLogo.id} id={upperLogo.id} url={upperLogo.url} upperLogo />
        </LogoWrapper>
      )}
      <LoginWrapper loadingFrame={loadingFrame} extraMargin={extraMargin}>
        {children}
      </LoginWrapper>

      <LogoWrapper style={{ alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        <BottomLogo url={SNP_LOGO} first />
        <BottomLogo url={SAP_LOGO} pullRight />
      </LogoWrapper>
    </StyledSapWelcome>
  );
};
