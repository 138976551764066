import React, { useState, useContext } from 'react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router';

import Livetime from './Livetime';
import OnlineUsers from './OnlineUsers';

import { PlayerContext, SoundContext } from '../../contexts';
import { DEVICE, getOS, SLINDA_URL } from '../../constants';
import './style.css';

const OS = getOS();
const Wrapper = styled.div`
  & > .react-player > div > iframe {
    pointer-events: ${(props) => props.isNotIos && 'none'};
    touch-action: none;
  }

  ${({ slinda }) =>
    slinda &&
    css`
      position: relative;
      width: 100%;
      top: 20%;
      height: 80%;
      background-color: transparent;

      @media ${DEVICE.tablet} {
        width: 75%;
      }
    `}
`;

export default ({ alias, showLiveTime, link }) => {
  const [time, setTime] = useState();
  const { isPlaying, tooglePlay } = useContext(PlayerContext);
  const { isMuted } = useContext(SoundContext);
  const firebase = useFirebase();
  const history = useHistory();

  const handleLogOut = () => {
    const userId = firebase.auth().currentUser.uid;
    const reference = firebase.database().ref(`/presence/${userId}`);
    reference
      .remove()
      .then(() => {
        history.replace(`/${alias}`);
        tooglePlay(false);
      })
      .catch((error) => {
        console.e(error);
        history.replace(`/${alias}`);
      });
  };

  return (
    <Wrapper className="player-wrapper" isNotIos={OS !== 'iOS'} slinda={alias === SLINDA_URL}>
      <OnlineUsers />
      {showLiveTime && <Livetime time={time} />}

      <ReactPlayer
        url={link}
        className="react-player"
        playing={isPlaying}
        playsinline
        width="100%"
        height="100%"
        onProgress={({ playedSeconds }) => {
          setTime(moment(new Date()).startOf('day').seconds(playedSeconds).format('H:mm:ss'));
        }}
        onEnded={handleLogOut}
        onError={handleLogOut}
        muted={isMuted}
        config={{
          youtube: {
            playerVars: {
              rel: 0,
              showinfo: 0,
              modestbranding: 1,
              fs: 0,
              disablekb: 1,
              host: 'http://www.youtube.com'
            }
          }
        }}
      />
    </Wrapper>
  );
};
