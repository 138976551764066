import React from 'react';

import TransmisionForm from './TransmisionForm';
import TransmisionsList from './TransmisionsList';

import { Container, Section } from './styled';
import { Column, Row } from '../../components/Layout';

export default () => (
  <Container>
    <Section withBorder>
      <h1>Panel</h1>
    </Section>
    <Section withPadding>
      <TransmisionForm />
    </Section>
    <Row>
      <Column>
        <Section withBorder>
          <h2>Tus transmisiones</h2>
        </Section>
        <Section withPadding>
          <TransmisionsList />
        </Section>
      </Column>
    </Row>
  </Container>
);
