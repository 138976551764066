/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import Workbook from 'react-excel-workbook';
import moment from 'moment';

// import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
// import { scaleLog } from 'd3-scale';

import Button from '../../../components/Button';
import { Row, Column } from '../../../components/Layout';
import { FormSubTitle } from './styled';
import { COLLECTIONS } from '../../../constants';

// const scale = scaleLog().base(10).nice();
const DATE_FORMAT = 'DD/MM/YYYY hh:mm:ss A';

export default ({ data }) => {
  const [onlines, setOnlines] = useState();
  const firestore = useFirestore();

  useEffect(() => {
    firestore
      .collection(COLLECTIONS.REPORTS)
      .doc(data.alias)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const result = doc.data();
          const onlinesResult = result.onlines
            .map((online) => ({
              ...online,
              created: online.created.toDate(),
              disconnectAt: online.disconnectAt && online.disconnectAt.toDate()
            }))
            .sort((a, b) => b.created - a.created);

          setOnlines(onlinesResult);
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);
  return (
    <>
      <Row>
        <FormSubTitle>Onlines</FormSubTitle>
      </Row>
      <Row>
        {data.alias && onlines ? (
          <Column>
            <Row style={{ alignItems: 'center' }}>
              <h4>Descargar excel onlines</h4>
              {onlines.length > 0 && (
                <Workbook
                  filename={`Onlines-${data.alias}.xlsx`}
                  element={<Button style={{ width: 'auto' }}>Descargar onlines!</Button>}
                >
                  <Workbook.Sheet
                    data={onlines.map((online) => {
                      if (online.hasOwnProperty('name')) {
                        return { ...online, firstName: online.name };
                      }
                      return { ...online };
                    })}
                    name="Onlines"
                  >
                    <Workbook.Column label="Nombre" value="firstName" />
                    <Workbook.Column label="Apellido" value="lastName" />
                    {/*
                    <Workbook.Column label="Nombre completo" value="fullName" />
                    */}
                    <Workbook.Column label="Email" value="email" />
                    {/*
                      <Workbook.Column label="Compañia" value="company" />
                    */}
                    <Workbook.Column
                      label="Ingreso"
                      value={(row) => moment(row.created).format(DATE_FORMAT) || ''}
                    />
                    <Workbook.Column
                      label="Egreso"
                      value={(row) =>
                        row.disconnectAt ? moment(row.disconnectAt).format(DATE_FORMAT) : ''
                      }
                    />
                    <Workbook.Column label="Cantidad en linea" value="quantity" />
                  </Workbook.Sheet>
                </Workbook>
              )}
            </Row>
            {/*
            <h3>Prueba de grafico - no usar</h3>
            <LineChart
              width={400}
              height={400}
              data={onlines}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              syncId="test"
            >
              <CartesianGrid
                stroke="#f5f5f5"
                verticalFill={['rgba(0, 0, 0, 0.2)', 'rgba(255, 255, 255, 0.3)']}
                horizontalFill={['#ccc', '#fff']}
              />
              <Legend />
              <XAxis dataKey="disconnectAt" axisLine={{ stroke: 'red' }} />
              <YAxis
                scale={scale}
                domain={[1, 'auto']}
                ticks={[1, 20, 50, 100, 200, 1000, 5000, 10000]}
              />
              <Tooltip />
              <Line type="monotone" dataKey="quantity" stroke="#ff7300" />
            </LineChart> */}
          </Column>
        ) : (
          <div>Reporte de onlines no disponible</div>
        )}
      </Row>
    </>
  );
};
