import React from 'react';

export const PopupContext = React.createContext();
export const QuestionContext = React.createContext();
export const UserContext = React.createContext();
export const IsEmojiBoxOpen = React.createContext();
export const PlayerContext = React.createContext();
export const SoundContext = React.createContext();
export const PinnedMessageContext = React.createContext();
export const CurrentPlayingLink = React.createContext();
export const VideoPlayContext = React.createContext();
