import React from 'react';
import { Row } from '../../Layout';
import { SmileIcon, InputContainer, Input, SendIcon } from './styled';
import useIsMobile from '../../../hooks/useIsMobile';

export default ({ text, onChange, handleOnKeyPress, toogle, onSubmitText }) => {
  const isMobile = useIsMobile();

  return (
    <Row style={{ flex: 1, justifyContent: 'space-between' }}>
      <InputContainer>
        {!isMobile && <SmileIcon onClick={toogle} />}
        <Input
          type="text"
          name="text"
          onKeyPress={handleOnKeyPress}
          onChange={onChange}
          placeholder="Escribe un mensaje"
          value={text}
        />
        {!isMobile && <SendIcon onClick={onSubmitText} />}
      </InputContainer>
    </Row>
  );
};
