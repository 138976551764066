import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Column } from '../../components/Layout';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;
  flex: 4;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-width: 0px;

  padding: 10px;
  font-size: 16px;

  &:focus {
    border: none;
    outline: none;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  box-sizing: border-box;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 5px;
  margin-bottom: 0px;
  :hover {
    cursor: pointer;
  }
`;

export default () => {
  const [sent, setSent] = useState(false);
  const [mail, setMail] = useState(null);

  const handleSent = () => {
    setSent(true);
    setMail('');
  };

  return (
    <Row>
      <Column style={{ padding: '10px 20px' }}>
        <p style={{ color: 'darksalmon' }}>Email</p>
        <Wrapper>
          <Input type="mail" onChange={(e) => setMail(e.target.value)} value={mail} />
          <Label onClick={handleSent}>{sent ? 'Gracias!' : 'Enviar'}</Label>
        </Wrapper>
      </Column>
    </Row>
  );
};
