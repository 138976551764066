/* eslint-disable no-confusing-arrow */
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faPlusCircle,
  faCheckCircle,
  faSignOutAlt,
  faUsers,
  faCalendarTimes,
  faArrowDown,
  faQuestionCircle,
  faBolt,
  faVolumeOff,
  faVolumeUp,
  faComments,
  faThumbtack,
  faEye,
  faEyeSlash,
  faLightbulb
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import styled, { css } from 'styled-components';
import { Column, Row } from './Layout';

const Wrapper = styled.div`
  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
        color: darkseagreen;
      }
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const PopupCloseStyledIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

export const Exclamation = ({ color, size, onClick, style }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon icon={faExclamationTriangle} size={size} color={color} style={style} />
  </Wrapper>
);

export const Add = ({ color, size, onClick, style }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon icon={faPlusCircle} size={size} color={color} style={style} />
  </Wrapper>
);

export const Confirm = ({ withLabel, color, size, onClick, style }) =>
  withLabel ? (
    <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Row>
        <Wrapper onClick={onClick}>
          <FontAwesomeIcon icon={faCheckCircle} size={size} color={color} style={style} />
        </Wrapper>
      </Row>
      <Row style={{ fontSize: 'small' }}>Confirmar </Row>
    </Column>
  ) : (
    <Wrapper onClick={onClick}>
      <FontAwesomeIcon icon={faCheckCircle} size={size} color={color} style={style} />
    </Wrapper>
  );

export const Exit = ({ color, size, rotation, onClick, style }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon
      icon={faSignOutAlt}
      size={size}
      color={color}
      style={style}
      rotation={rotation}
    />
  </Wrapper>
);

export const Users = ({ color, size, style }) => (
  <Wrapper>
    <FontAwesomeIcon icon={faUsers} size={size} color={color} style={style} />
  </Wrapper>
);

export const Agenda = React.memo(({ onClick, color, size, style }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon icon={faCalendarTimes} size={size} color={color} style={style} />
  </Wrapper>
));

export const PopupClose = React.memo(({ onClick, color, size, style }) => (
  <Wrapper onClick={onClick}>
    <PopupCloseStyledIcon icon={faArrowDown} size={size} color={color} style={style} />
  </Wrapper>
));

export const Facebook = ({ onClick, color, size, style }) => (
  <Wrapper onClick={onClick}>
    <PopupCloseStyledIcon icon={faFacebook} size={size} color={color} style={style} />
  </Wrapper>
);

export const Instagram = ({ onClick, color, size, style }) => (
  <Wrapper onClick={onClick}>
    <PopupCloseStyledIcon icon={faInstagram} size={size} color={color} style={style} />
  </Wrapper>
);

export const Linkedln = ({ onClick, color, size, style }) => (
  <Wrapper onClick={onClick}>
    <PopupCloseStyledIcon icon={faLinkedin} size={size} color={color} style={style} />
  </Wrapper>
);

const Arrow = styled.div`
  position: relative;
  top: 15px;
  height: 10px;
  width: 10px;
  border: solid ${(props) => props.theme.colors.black};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;

  :hover {
    cursor: pointer;
  }
`;

export const ArrowUp = styled(Arrow)`
  left: 20px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`;

export const ArrowRight = styled(Arrow)`
  left: 10px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

export const ArrowLeft = styled(Arrow)`
  left: 20px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

export const Question = React.memo(({ onClick, size }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon size={size} icon={faQuestionCircle} />
  </Wrapper>
));

export const Light = React.memo(({ onClick, size }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon size={size} icon={faLightbulb} />
  </Wrapper>
));

export const Contest = React.memo(({ onClick, size }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon size={size} icon={faBolt} />
  </Wrapper>
));

export const VolumeOn = React.memo(({ onClick, size }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon size={size} icon={faVolumeUp} />
  </Wrapper>
));

export const Mute = React.memo(({ onClick, size }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon size={size} icon={faVolumeOff} />
  </Wrapper>
));

export const Pin = React.memo(({ size }) => (
  <Wrapper>
    <FontAwesomeIcon size={size} icon={faThumbtack} />
  </Wrapper>
));

export const ChatIcon = React.memo(({ size }) => (
  <Wrapper onClick={() => null}>
    <FontAwesomeIcon size={size} icon={faComments} rotation={45} />
  </Wrapper>
));

export const EyeOpen = React.memo(({ onClick, size }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon size={size} icon={faEye} color="white" style={{ fontSize: '1.5rem' }} />
  </Wrapper>
));

export const EyeClosed = React.memo(({ onClick, size }) => (
  <Wrapper onClick={onClick}>
    <FontAwesomeIcon size={size} icon={faEyeSlash} color="white" style={{ fontSize: '1.5rem' }} />
  </Wrapper>
));

const ZOOM_LOGO = require('../assets/zoom-icon.png');

const ZoomLogo = styled.img`
  width: 2rem;
`;

export const ZoomBrand = React.memo(({ onClick }) => (
  <Wrapper onClick={onClick}>
    <ZoomLogo src={ZOOM_LOGO} alt="zoom" />
  </Wrapper>
));
