import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import Workbook from 'react-excel-workbook';
import moment from 'moment';

import Button from '../../../components/Button';
import { Row, Column } from '../../../components/Layout';
import { FormSubTitle } from './styled';
import { COLLECTIONS } from '../../../constants';

export default ({ data }) => {
  const [registrados, setRegistrados] = useState();
  const firestore = useFirestore();

  useEffect(() => {
    firestore
      .collection(COLLECTIONS.PRE_REGISTER)
      .where('alias', '==', data.alias)
      .limit(1)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          const docData = doc.data();
          setRegistrados(docData.registered);
        });
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }, []);

  return (
    <>
      <Row>
        <FormSubTitle>Registrados</FormSubTitle>
      </Row>
      <Row>
        {data.alias && registrados ? (
          <Column>
            <Row style={{ alignItems: 'center' }}>
              <h4>Descargar reporte de registrados: </h4>
              {registrados && (
                <Workbook
                  filename={`registrados-${moment().format('DDMMYYY')}.xlsx`}
                  element={<Button style={{ width: 'auto' }}>Descargar registrados!</Button>}
                >
                  <Workbook.Sheet data={registrados} name="Hoja 1">
                    <Workbook.Column label="Nombre y Apellido" value="fullName" />
                    <Workbook.Column label="Company" value="company" />
                    <Workbook.Column label="Cargo" value="charge" />
                    <Workbook.Column label="País" value="country" />
                    <Workbook.Column label="Email" value="email" />
                    <Workbook.Column
                      label="Ingreso"
                      value={(row) => moment(row.created.toDate()).format('DD//MM//YYY hh:mm:ss A')}
                    />
                  </Workbook.Sheet>
                </Workbook>
              )}
            </Row>
          </Column>
        ) : (
          <div>Reporte de registrados no disponible</div>
        )}
      </Row>
    </>
  );
};
