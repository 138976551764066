import styled, { css } from 'styled-components';
import { DEVICE, TIENDA_DIGGIT_EVENT } from '../../../constants';
import { Row, Column } from '../../Layout';

export const Container = styled(Row)`
  justify-content: ${(props) => (props.self ? 'flex-end' : 'flex-start')};

  ${({ event }) =>
    event === TIENDA_DIGGIT_EVENT &&
    css`
      font-weight: bold;
      color: black;
    `}

  @media ${DEVICE.mobileS} {
    margin: 10px 0px;
  }
`;

export const Content = styled(Row)`
  background-color: white;
  border-radius: 5px;

  min-height: 48px;
  height: auto;

  ${({ self }) =>
    self &&
    css`
      font-family: 'Ubuntu', sans-serif;
      font-weight: bolder;
      background-color: lightskyblue;
      opacity: 0.95;
    `}

  @media ${DEVICE.mobileS} {
    width: 150px;
    padding: 5px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  @media ${DEVICE.tablet} {
    width: 225px;
    padding: 5px;
    margin-top: 2px;
  }
`;

export const TimeWrapper = styled(Column)`
  justify-content: flex-end;
`;
