import React from 'react';
import styled from 'styled-components';
import { Row, Column } from '../Layout';
import { DEVICE } from '../../constants';

const LiveTime = styled(Row)`
  position: absolute;
  left: 3%;
  z-index: 2;
  text-align: center;
  color: white;
  font-weight: bolder;
  box-shadow: 2px 2px 3px #999;

  @media ${DEVICE.mobileS} {
    top: 20%;
    font-size: 0.6rem;
  }

  @media ${DEVICE.tablet} {
    top: 10%;
    font-size: 0.9rem;
  }

  @media ${DEVICE.laptopL} {
    top: 9%;
    font-size: 0.9rem;
  }
`;

const LiveWrapper = styled.div`
  background: red;
  border: 1px solid;
  min-width: 50px;
`;

const TimeWrapper = styled.div`
  text-align: center;
  color: white;
  font-weight: bolder;
  padding: 1px 5px;
  background-color: black;
`;

export default React.memo(({ time }) => (
  <LiveTime>
    <Column>
      <LiveWrapper>Live</LiveWrapper>
    </Column>
    <Column>
      <TimeWrapper>{time}</TimeWrapper>
    </Column>
  </LiveTime>
));
