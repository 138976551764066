import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { VideoPlayContext } from '../../../contexts';
import useIsMobile from '../../../hooks/useIsMobile';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-width: 15rem;
  margin: 3rem 0;
`;

const VideoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35vmax;
  min-height: 25vmax;
  border: 2px solid black;
  color: ${(props) => props.theme.colors.secondaryColor};
  font-size: ${(props) => props.theme.fontSize.small};
  background-color: ${(props) => props.theme.secondaryColor};

  background-image: url(${(props) => props.preview});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
`;

const Action = styled.div`
  align-items: center;
  min-width: 5rem;
  margin-top: 1rem;
  padding: 0.5vmax;
  background-color: #c8578e;
  color: white;
  border-radius: 0.5vmax;
  font-size: ${(props) => props.theme.fontSize.small};

  & > button {
    display: flex;
    flex-flow: row wrap;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }

  & > button > span {
    font-family: AvantGardeBook;
  }

  & > button > div {
    margin-left: 5px;
    font-family: AvantGardeBookBold;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2vmax;
  color: white;
`;

export default ({ data }) => {
  const { setVideo } = useContext(VideoPlayContext);
  const isMobile = useIsMobile();

  const handleClick = () => {
    setVideo(data.video);
  };

  return (
    <Container>
      <VideoBox onClick={handleClick} preview={data.preview} isMobile={isMobile}>
        <Icon icon={faPlay} />
      </VideoBox>
      <Action onClick={() => window.open(data.trivia, '_blank')}>
        <button type="button">
          <span>Trivia:</span>
          <div>{`${data.title}`}</div>
        </button>
      </Action>
    </Container>
  );
};
