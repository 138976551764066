import { useMediaQuery } from 'react-responsive';

export default () => {
  const isMobile = useMediaQuery({
    minDeviceWidth: 320,
    maxDeviceWidth: 767
  });

  return isMobile;
};
