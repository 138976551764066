/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Section, Container } from './styled';
import { Column, Row } from '../../components/Layout';
import { COLLECTIONS } from '../../constants';

const BACKGROUND = require('../../assets/monitor_bkg.jpeg');

const Entry = styled(Row)`
  width: 50%;
  min-height: 60px;
  border: 1px solid;
  padding: 10px;
  box-shadow: 2px 2px grey;
  margin-bottom: 15px;
  font-size: large !important;
  background: white;
  padding: 10px;
  opacity: 0.9;
  border-radius: 7px;

  > ${Column} {
    padding: 0 15px 0 25px;
  }
`;

const Title = styled.h1`
  background: white;
  padding: 10px;
  opacity: 0.9;
  border-radius: 7px;
`;

export default () => {
  const location = useLocation();
  const alias = location.pathname.split('/')[1];

  const getMessagesFromState = (state) =>
    state.firestore.ordered[COLLECTIONS.QUESTIONS] &&
    state.firestore.ordered[COLLECTIONS.QUESTIONS][0] &&
    state.firestore.ordered[COLLECTIONS.QUESTIONS][0].questions
      ? state.firestore.ordered[COLLECTIONS.QUESTIONS][0].questions
      : [];

  useFirestoreConnect(() => [{ collection: COLLECTIONS.QUESTIONS, doc: alias }]);
  const entries = useSelector((state) =>
    getMessagesFromState(state)
      .map((message) => ({ ...message, created: message.created.toDate() }))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
  );

  return (
    <Container background={BACKGROUND}>
      <Section>
        <Column>
          <Row style={{ justifyContent: 'center', padding: '2rem' }}>
            <Title>Preguntas realizadas</Title>
          </Row>
          <Row>
            <Column style={{ alignItems: 'center', padding: '2rem' }}>
              {entries.map(({ message, created, user }) => {
                const time = moment(created).format('hh:mm A');
                return (
                  <Entry>
                    <Column style={{ flex: 3, wordBreak: 'break-all', paddingRight: '5px' }}>
                      {message}
                    </Column>
                    <Column style={{ padding: '0 15px 0 25px', alignContent: 'center' }}>
                      {user}
                    </Column>
                    <Column>{time}</Column>
                  </Entry>
                );
              })}
            </Column>
          </Row>
        </Column>
      </Section>
    </Container>
  );
};
