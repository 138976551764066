import moment from 'moment';
import React from 'react';
import Countdown from 'react-countdown';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import useIsMobile from '../../../hooks/useIsMobile';
import { Container } from '../styled';
import AvantGardeBookBold from '../font/AGGDemiBold.otf';

const BACKGROUND_URL = require('./img/background.png');

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 25%;
  height: 32vmax;
  width: 80vmax;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;

  @font-face {
    font-family: 'AvantGardeBookBold';
    src: url(${AvantGardeBookBold}) format('opentype');
  }

  font-family: 'AvantGardeBookBold';

  & > * {
    width: 50vmax;
    text-align: center;
  }

  & > {
    margin-bottom: 2vh;
  }

  ${({ isTablet }) =>
    isTablet &&
    css`
      top: 35%;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      top: 35%;
      height: 50vmax;
      width: 50vmax;
      justify-content: space-evenly;
      background-image: url(${(props) => props.imageUrl});

      & > * {
        width: 35vmax;
      }
    `}
`;

const CountdownWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background-color: #c8578e;
  height: 10vmax;
  border-radius: 2rem;
  align-items: center;
  font-size: 3vmax;

  & > div {
    text-align: center;
    margin: 0 2rem;
  }

  & > div > div {
    text-align: center;
    font-size: 1.8vmax;
  }

  & > div > p {
    font-size: 3vmax;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: auto;
      padding: 2vmax;
      width: auto;
    `}
`;

export default () => {
  const isMobile = useIsMobile();
  const isTablet = useMediaQuery({
    minDeviceWidth: 767,
    maxDeviceWidth: 1023
  });

  const renderer = ({ days, hours, minutes, seconds }) => (
    <CountdownWrapper isMobile={isMobile}>
      <div>
        <p>{days}</p>
        <div>Días</div>
      </div>
      <div>
        <p>{hours}</p>
        <div>Horas</div>
      </div>
      <div>
        <p>{minutes}</p>
        <div>Minutos</div>
      </div>
      <div>
        <p>{seconds}</p>
        <div>Segundos</div>
      </div>
    </CountdownWrapper>
  );
  return (
    <StyledContainer imageUrl={BACKGROUND_URL} isMobile={isMobile} isTablet={isTablet}>
      <h1>EL EVENTO COMIENZA</h1>
      <Countdown date={moment('2021-06-09 19:00:00').toISOString()} renderer={renderer} />
    </StyledContainer>
  );
};
