import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { useFirestore } from 'react-redux-firebase';

import Button from '../../../components/Button';
import { Row, Column } from '../../../components/Layout';
import { FormSubTitle } from './styled';
import { COLLECTIONS } from '../../../constants';
import LoadingSpinner from '../../../components/LoadingSpinner';

const Messages = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  min-height: 100px;
  max-height: 120px;
  overflow: auto;
`;

export default ({ data, getData }) => {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState();

  useEffect(() => {
    let theResult;
    setLoading(true);
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .doc(data.docId)
      .get()
      .then((resultMessages) => {
        theResult = resultMessages.data();
        if (!theResult) {
          firestore
            .collection(COLLECTIONS.MODERATE_CHAT)
            .doc(data.alias)
            .get()
            .then((result) => {
              theResult = result.data();
              const theMessages = theResult.messages;
              setMessages(theMessages);
              setLoading(false);
            });
        } else {
          const theMessages = theResult.messages;
          setMessages(theMessages);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [data.alias]);

  const handleBorrarClick = () => {
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .where('id', '==', data.id)
      .limit(1)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          firestore
            .collection(COLLECTIONS.STREAMINGS)
            .doc(doc.id)
            .update({
              messages: []
            })
            .then(() => getData());
        });
      });
  };

  if (loading || !messages) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Row>
        <FormSubTitle>Chat</FormSubTitle>
      </Row>
      <Row>
        {messages && messages.length > 0 ? (
          <Column>
            <Row style={{ alignItems: 'center' }}>
              <Messages>{`Cantidad de mensajes : ${messages.length}`}</Messages>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <CSVLink
                data={messages.map((message) => ({
                  Nombre: message.fullName,
                  Mensaje: message.text,
                  Ingresado: message.created.toDate()
                }))}
                filename={`chat_${data.alias}.csv`}
              >
                <Button>Descargar Chat</Button>
              </CSVLink>
              <Button style={{ marginLeft: '15px' }} onClick={handleBorrarClick}>
                Borrar Chat
              </Button>
            </Row>
          </Column>
        ) : (
          <div>No hay mensajes en el chat</div>
        )}
      </Row>
    </>
  );
};
