/* eslint-disable spaced-comment */
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { useFirebase, useFirestore } from 'react-redux-firebase';

import Activities from './Activities';
import Question from './Question';

import { Contest, Exit, VolumeOn, Mute, Light } from '../Icons';
import { PlayerContext, SoundContext } from '../../contexts';
import SapGermanyControl from './SapGermanyControl';
import { COLLECTIONS, LOCAL_STORAGE, NEW_SAP_EVENT, TIENDA_DIGGIT_EVENT } from '../../constants';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  opacity: 0.9;
  padding: 15px 0;
  justify-content: space-around;
  align-items: end;
`;

let reportRef;

export default ({ hasAgenda, alias, hasTrivia, hasContest, hasQuestion }) => {
  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const userId = firebase.auth().currentUser.uid;

  const { tooglePlay } = useContext(PlayerContext);
  const { isMuted, setMuted } = useContext(SoundContext);

  useEffect(() => {
    const init = async () => {
      reportRef = await firestore.collection(COLLECTIONS.REPORTS).doc(`${alias}`);
    };
    init();
  }, [alias, firestore]);

  const updateOnlines = async (quantity) => {
    const loginid = sessionStorage.getItem(LOCAL_STORAGE.LOGIN_ID);
    //get onlines report data
    let actualOnlines;
    await reportRef.get().then((doc) => {
      const result = doc.data();
      actualOnlines = result.onlines;
    });
    const updatedOnlines = [];

    //// copy array from reference to empty updatedComments array
    actualOnlines.forEach((online) => {
      updatedOnlines.push(online);
    });

    //// grab index which i want to update
    const numberArray = actualOnlines.findIndex(
      (user) => user.userId === userId && user.loginId === loginid
    );
    //// update in specific index array
    updatedOnlines[numberArray].disconnectAt = firestore.Timestamp.now();
    updatedOnlines[numberArray].quantity = quantity;
    //// replace updated array in firestore
    reportRef.update({
      onlines: updatedOnlines
    });
  };

  const handleLogOut = () => {
    const userPresenceRef = firebase.database().ref(`/presence/${userId}`);
    let quantity;
    firebase
      .database()
      .ref('/presence')
      .once('value', (data) => {
        quantity = data.numChildren();
      });
    userPresenceRef
      .remove()
      .then(() => {
        history.replace(`/${alias}`);
        tooglePlay(false);
        updateOnlines(quantity);
      })
      .catch((error) => {
        console.error(error);
        history.replace(`/${alias}`);
      });
  };

  return (
    <Container event={TIENDA_DIGGIT_EVENT}>
      {hasQuestion && <Question alias={alias} />}
      {hasTrivia && (
        <>
          <div data-tip data-for="triviaTip">
            <Light size="2x" onClick={() => null} />
          </div>
          <ReactTooltip id="triviaTip" place="top" effect="float">
            Trivia! (próximamente)
          </ReactTooltip>
        </>
      )}
      {hasContest && (
        <>
          <div data-tip data-for="contestTip">
            <Contest size="2x" onClick={() => null} />
          </div>
          <ReactTooltip id="contestTip" place="top" effect="float">
            Ir al sorteo! (próximamente)
          </ReactTooltip>
        </>
      )}
      {hasAgenda && <Activities />}
      <>
        <div data-tip data-for="soundTip">
          {isMuted ? (
            <Mute onClick={() => setMuted(false)} size="2x" />
          ) : (
            <VolumeOn onClick={() => setMuted(true)} size="2x" />
          )}
        </div>
        <ReactTooltip id="soundTip" place="top" effect="float">
          Mute
        </ReactTooltip>
      </>
      {alias === NEW_SAP_EVENT && (
        <>
          <SapGermanyControl />
        </>
      )}
      {/*demoEvent && 
      (
      <>
        <div data-tip data-for="zoomTip">
          <ZoomBrand onClick={() => history.push(`/${alias}/zoom`)} />
        </div>
        <ReactTooltip id="zoomTip" place="top" effect="float">
          Unirse a una sala de prueba
        </ReactTooltip>
      </>
      )*/}
      <>
        <div data-tip data-for="exitTip">
          <Exit onClick={handleLogOut} size="2x" />
        </div>
        <ReactTooltip id="exitTip" place="top" effect="float">
          Salir
        </ReactTooltip>
      </>
    </Container>
  );
};
