import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  background-color: transparent;
  height: 100%;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export default ({ src }) => (
  <Wrapper>
    <Image src={src} />
  </Wrapper>
);
