import React, { useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import hash from 'object-hash';

import { Column, Row } from '../../../components/Layout';
import { COLLECTIONS } from '../../../constants';
import Button from '../../../components/Button';
import { InputContainer } from './styled';
import LoadingSpinner from '../../../components/LoadingSpinner';

export default () => {
  const [loading, setLoading] = useState(false);

  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebase.auth);

  const addNewStreaming = (e) => {
    e.preventDefault();
    setLoading(true);
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .add({ id: hash(Math.random()), alias: 'evento', userId: auth.uid, created: new Date() })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Row>
      <Column>
        <InputContainer style={{ flex: 1 }}>
          <Column>
            <Row style={{ marginBottom: 'auto', marginTop: '15px' }}>
              <Column>
                <Row>
                  <Button onClick={addNewStreaming}>Generar nueva transmision</Button>
                </Row>
                <Row style={{ marginTop: '10px', justifyContent: 'center' }}>
                  <LoadingSpinner loading={loading} />
                </Row>
              </Column>
            </Row>
          </Column>
        </InputContainer>
      </Column>
    </Row>
  );
};
