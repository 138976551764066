/* eslint-disable indent */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect, createRef, useContext } from 'react';
import { animated } from 'react-spring';
import { useFirestoreConnect, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import hash from 'object-hash';
import { useLocation } from 'react-router';

import Message from './Message';
import Input from './Input';
import { Row, Column } from '../Layout';
import Picker from './Picker';

import { IsEmojiBoxOpen, UserContext, PinnedMessageContext } from '../../contexts';
import { COLLECTIONS, NEW_SAP_EVENT } from '../../constants';
import {
  Wrapper,
  MessagesContainer,
  ScrollableWrapper,
  MessageInputWrapper,
  PinnedMessage,
  PinContainer
} from './styled';
import { Pin } from '../Icons';

let docRef;

export default ({ animatedProps }) => {
  const [text, setText] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { tooglePicker } = useContext(IsEmojiBoxOpen);
  const { pinnedMessage } = useContext(PinnedMessageContext);
  const firestore = useFirestore();
  const messageRef = createRef();
  const location = useLocation();
  const alias = location.pathname.split('/')[1];
  const { user } = useContext(UserContext);
  const { id, firstName, name, lastName, userId, fullName } = user;

  useEffect(() => {
    const init = async () => {
      if (alias === NEW_SAP_EVENT) {
        docRef = await firestore.collection(COLLECTIONS.MODERATE_CHAT).doc(NEW_SAP_EVENT);
      } else {
        docRef = await firestore.collection(COLLECTIONS.STREAMINGS).doc(id);
      }
    };
    init();
    messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [firestore, messageRef, id]);

  const getMessagesFromState = (state) =>
    state.firestore.ordered[COLLECTIONS.STREAMINGS] &&
    state.firestore.ordered[COLLECTIONS.STREAMINGS][0] &&
    state.firestore.ordered[COLLECTIONS.STREAMINGS][0].messages
      ? state.firestore.ordered[COLLECTIONS.STREAMINGS][0].messages
      : [];

  useFirestoreConnect(() => [{ collection: COLLECTIONS.STREAMINGS, doc: id }]);
  const messages = useSelector((state) =>
    getMessagesFromState(state)
      .map((message) => ({ ...message, created: message.created.toDate() }))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
  );

  const toogle = () => {
    setShowEmojiPicker(!showEmojiPicker);
    tooglePicker(!showEmojiPicker);
  };

  const onEmojiClick = (emoji) => {
    setText((prevState) => `${prevState}${emoji.native}`);
    toogle();
  };

  const submitText = () => {
    if (text) {
      const theName = name || firstName;
      const userName = fullName || `${theName} ${lastName}`;
      const time = firestore.Timestamp.now();
      let values = {
        id: hash(Math.random()),
        text,
        userId,
        created: time
      };
      values = { ...values, userName };
      docRef.update({
        messages: firestore.FieldValue.arrayUnion(values)
      });
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setText('');
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitText();
    }
  };

  return (
    <animated.div style={{ ...animatedProps, height: '100%' }}>
      <Row style={{ flex: 1, height: '100%' }}>
        <Wrapper>
          <MessagesContainer>
            {pinnedMessage && (
              <PinnedMessage>
                <PinContainer>
                  <Pin size="2x" />
                </PinContainer>
                <Column
                  style={{
                    flex: 6,
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px'
                  }}
                >
                  <a href={pinnedMessage} target="_blank" rel="noopener noreferrer">
                    {pinnedMessage}
                  </a>
                </Column>
              </PinnedMessage>
            )}
            <ScrollableWrapper>
              {messages &&
                messages.map((message) => (
                  <Message
                    key={message.id}
                    message={message}
                    self={message.userId === userId}
                    event={alias}
                  />
                ))}
              <div ref={messageRef} style={{ minHeight: '80px', backgroundColor: 'transparent' }} />
            </ScrollableWrapper>
          </MessagesContainer>
          <MessageInputWrapper>
            <Input
              text={text}
              onChange={(e) => setText(e.target.value)}
              handleOnKeyPress={handleOnKeyPress}
              onSubmitText={submitText}
              toogle={toogle}
            />
            {showEmojiPicker && <Picker onEmojiClick={onEmojiClick} tooglePicker={toogle} />}
          </MessageInputWrapper>
        </Wrapper>
      </Row>
    </animated.div>
  );
};
