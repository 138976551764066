import React, { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { Redirect } from 'react-router-dom';
import hash from 'object-hash';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { toast } from 'react-toastify';

import { StyledSapWelcome } from '.';
import LoadingSpinner from '../../components/LoadingSpinner';
import { COLLECTIONS, NEW_SAP_EVENT, SAP_URL } from '../../constants';
import useCountdown from '../../hooks/useCountdown';
import useUrlAlias from '../../hooks/useUrlAlias';

import {
  BottomLogo,
  CalendarLogo,
  CountdownWrapper,
  Error,
  Input,
  InputContainer,
  Logo,
  LogoWrapper,
  Button,
  StyledRow,
  Content,
  GermanyWrapper,
  BottomLogos
} from './styled';
import { Column, Row } from '../../components/Layout';
import useIsMobile from '../../hooks/useIsMobile';
import useBackground from '../../hooks/useBackground';
import useUpperLogo from '../../hooks/useUpperLogo';
import { UserContext } from '../../contexts';

const CALENDARIO = require('./img/calendario.png');
const TEXTO_LANDING = require('./img/texto_landing.png');
const IMAGE_URL = require('../../assets/welcome.png');
const AGENDA = require('./img/placa_agenda.png');
const TEXT_COUNTDOWN = require('./img/textobajo_countdown.png');
const TEXT_GERMANY_COUNTDOWN = require('./img/sap-germany/textobajo_countdown.png');

const SAP_LOGO = require('./img/sap.png');
const SNP_LOGO = require('./img/snp.png');

const DELOITTE_LOGO = require('./img/sap-germany/deloitte.png');
const GOOGLE_CLOUD_LOGO = require('./img/sap-germany/googleCloud.png');
const GLOBANT_LOGO = require('./img/sap-germany/globant.png');
const SEIDOR_LOGO = require('./img/sap-germany/seidor.png');

let images;

let reportRef;

export default ({ noFooter }) => {
  const [redirect, setRedirect] = useState(null);
  const [loginIn, setLoginIn] = useState(false);
  const isMobile = useIsMobile();
  const { setLogged } = useContext(UserContext);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const alias = useUrlAlias();

  const { loadingCountdown, startAt } = useCountdown(alias);
  const { loadingBackground, background } = useBackground(alias);
  const { loadingUpperLogo, upperLogo } = useUpperLogo(alias);
  const [mail, setMail] = useState();
  const [error, setError] = useState(false);

  if (alias === SAP_URL) {
    images = {
      calendar: CALENDARIO,
      landingText: TEXTO_LANDING,
      agenda: AGENDA,
      textCountdown: TEXT_COUNTDOWN,
      logos: [SNP_LOGO, SAP_LOGO]
    };
  }

  if (alias === NEW_SAP_EVENT) {
    images = {
      textCountdown: TEXT_GERMANY_COUNTDOWN,
      logos: [DELOITTE_LOGO, GLOBANT_LOGO, GOOGLE_CLOUD_LOGO, SEIDOR_LOGO, SNP_LOGO, SAP_LOGO]
    };
  }

  useEffect(() => {
    const init = async () => {
      reportRef = await firestore.collection(COLLECTIONS.REPORTS).doc(`${alias}`);
      document.title = alias.toLocaleUpperCase();
      const faviconEl = document.getElementById('favicon');
      faviconEl.href =
        'https://icons.iconarchive.com/icons/hopstarter/button/16/Button-Play-icon.png';
    };
    init();
  }, []);

  const goStreaming = () => {
    if (!mail) {
      setError(true);
      return;
    }
    setError(false);
    setLoginIn(true);
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        let registeredUser;
        firebase
          .auth()
          .signInAnonymously()
          .then(async () => {
            await firestore
              .collection(COLLECTIONS.PRE_REGISTER)
              .doc(alias)
              .get()
              .then((result) => {
                if (result.exists) {
                  const data = result.data();
                  registeredUser = data.registered.find((user) => user.email === mail);
                } else {
                  toast.error('Ocurrio un error');
                }
              });
            if (!registeredUser) {
              setLoginIn(false);
              toast.error('El correo no está registrado!');
            } else {
              const loggedUser = {
                ...registeredUser,
                urlAlias: alias,
                id: alias,
                userId: hash(Math.random())
              };
              reportRef.update({
                onlines: firestore.FieldValue.arrayUnion({
                  ...loggedUser,
                  idOnline: hash(Math.random()),
                  created: firestore.Timestamp.now()
                })
              });
              localStorage.setItem('last-event-url', alias);

              setTimeout(() => {
                setLogged(loggedUser);
                setRedirect(true);
                setLoginIn(false);
              }, 1500);
            }
          });
      });
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/${alias}/streaming`
        }}
      />
    );
  }

  if (loadingCountdown || loadingUpperLogo || loadingBackground || loginIn) {
    return (
      <Row style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingSpinner />
      </Row>
    );
  }

  const renderer = ({ days, hours, minutes }) => (
    <Row style={{ width: '100%' }}>
      <Column>
        <StyledRow title>Días</StyledRow>
        <StyledRow value>{days}</StyledRow>
      </Column>
      <Column>
        <StyledRow title>Horas</StyledRow>
        <StyledRow value>{hours}</StyledRow>
      </Column>
      <Column>
        <StyledRow title>Minutos</StyledRow>
        <StyledRow value>{minutes}</StyledRow>
      </Column>
    </Row>
  );

  if (alias === NEW_SAP_EVENT) {
    return (
      <StyledSapWelcome
        style={{ justifyContent: 'center', padding: '0' }}
        isMobile={isMobile}
        withBackgroundImage={(background && background.url) || IMAGE_URL}
      >
        {upperLogo && (
          <LogoWrapper style={{ marginTop: '3rem' }}>
            <Logo key={upperLogo.id} id={upperLogo.id} url={upperLogo.url} upperLogo />
          </LogoWrapper>
        )}
        <GermanyWrapper>
          <Column style={{ alignItems: 'center' }}>
            {startAt && (
              <CountdownWrapper style={{ marginTop: '4rem' }} nobackground>
                {images.calendar && <CalendarLogo src={images.calendar} alt="calendar" />}
                <Countdown date={startAt.toDate()} renderer={renderer} />
              </CountdownWrapper>
            )}

            <Content
              src={images.textCountdown}
              alt="TEXT_COUNTDOWN"
              title
              style={{ margin: '2rem 0', maxWidth: '100%' }}
            />
            <Row style={{ width: '40%' }}>
              <InputContainer
                style={{
                  borderRadius: '7px'
                }}
              >
                <Input
                  style={{ fontSize: 'large', marginTop: '2rem' }}
                  name="fullName"
                  onChange={(e) => setMail(e.target.value)}
                  placeholder="Email"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      goStreaming();
                    }
                  }}
                />
                {error && (
                  <Error style={{ fontSize: 'large' }}>Debe ingresar el correo registrado</Error>
                )}
                <Row
                  style={{
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  <Button type="button" onClick={goStreaming}>
                    Ingresar
                  </Button>
                </Row>
              </InputContainer>
            </Row>
          </Column>
        </GermanyWrapper>
        <BottomLogos>
          {images.logos.map((logo, i) => (
            <BottomLogo url={logo} first={i === 0} pullRight={i === images.logos.length - 1} />
          ))}
        </BottomLogos>
      </StyledSapWelcome>
    );
  }

  return (
    <StyledSapWelcome
      style={{ justifyContent: 'center', padding: '0' }}
      isMobile={isMobile}
      withBackgroundImage={(background && background.url) || IMAGE_URL}
    >
      {upperLogo && (
        <LogoWrapper style={{ marginTop: '8rem' }}>
          <Logo key={upperLogo.id} id={upperLogo.id} url={upperLogo.url} upperLogo />
        </LogoWrapper>
      )}
      {startAt && (
        <CountdownWrapper style={{ marginTop: '4rem' }}>
          {images.calendar && <CalendarLogo src={images.calendar} alt="calendar" />}
          <Countdown date={startAt.toDate()} renderer={renderer} />
        </CountdownWrapper>
      )}

      <Content src={images.textCountdown} alt="TEXT_COUNTDOWN" title />
      <Row style={{ width: '40%' }}>
        <InputContainer
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            padding: '20px',
            borderRadius: '7px'
          }}
        >
          <Input
            style={{ fontSize: 'large', marginTop: '2rem' }}
            name="fullName"
            onChange={(e) => setMail(e.target.value)}
            placeholder="Email"
          />
          {error && <Error style={{ fontSize: 'large' }}>Debe ingresar el correo registrado</Error>}
          <Row
            style={{
              justifyContent: 'center',
              marginTop: '20px'
            }}
          >
            <Button type="button" onClick={goStreaming}>
              Ingresar
            </Button>
          </Row>
        </InputContainer>
      </Row>

      <Content src={images.landingText} alt="text" title />
      {!noFooter && <Content src={AGENDA} alt="agenda" agenda />}
      <LogoWrapper
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          marginBottom: '5rem'
        }}
      >
        {images.logos.map((logo, i) => (
          <BottomLogo url={logo} first={i === 0} second={i === 1} pullRight={i === 2} />
        ))}
      </LogoWrapper>
    </StyledSapWelcome>
  );
};
