import React, { useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import styled from 'styled-components';

const StyledFlagSelect = styled(ReactFlagsSelect)`
  input {
    background-color: ${(props) => (props.color ? props.color : 'black')};
    color: azure;
  }

  .flag-select__options {
    background-color: ${(props) => (props.color ? props.color : 'black')};
    color: azure;
    width: max-content;
  }

  .flag-select__option {
    :hover {
      color: black;
    }
  }

  .flag-select__option__icon {
    display: inline-block;
    vertical-align: top;
  }
`;

const defaultCountry = 'AR';

export default ({ onChange, allCountries, defaultValue, color }) => {
  useEffect(() => {
    defaultValue(defaultCountry);
  }, []);

  return (
    <StyledFlagSelect
      name="country"
      defaultCountry={defaultCountry}
      onSelect={onChange}
      placeholder="Buscar"
      showSelectedLabel
      searchable
      countries={!allCountries && [('AR', 'US', 'UY', 'CL', 'MX')]}
      color={color}
    />
  );
};
