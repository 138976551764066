import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import { useFirebase, useFirestore } from 'react-redux-firebase';

import PreRegister from './PreRegister';
import Header from './Header';
import Speakers from './Speakers';
import StreamingSection from './StreamingSection';
import Contest from './Contest';
import LibrarySection from './LibrarySection';
import Theme from './theme';
import Footer from './Footer';
import OnDemand from './OnDemand';
import { UserContext, VideoPlayContext } from '../../contexts';
import { COLLECTIONS, getOS, SLINDA_URL } from '../../constants';

import './style.css';
import useIsMobile from '../../hooks/useIsMobile';
import FontStyles from './font';

Modal.setAppElement('#root');

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: 'AvantGardeDemi';
`;

const modalStyle = (isMobile) => {
  const width = isMobile ? '100vw' : '60vw';
  return {
    content: {
      width,
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };
};

const OS = getOS();
const Wrapper = styled.div`
  & > div > div > iframe {
    pointer-events: ${(props) => props.isNotIos && 'none'};
  }
`;

const alias = SLINDA_URL;

export default () => {
  const [videoModal, setVideoModal] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);
  const isMobile = useIsMobile();
  const firebase = useFirebase();
  const firestore = useFirestore();

  const changeVideoModalStatus = (video) => setVideoModal(video);
  const { setLogged } = useContext(UserContext);

  useEffect(() => {
    document.title = 'Slinda';
    const faviconEl = document.getElementById('favicon');
    faviconEl.href =
      'https://icons.iconarchive.com/icons/hopstarter/button/16/Button-Play-icon.png';
  }, [alias, firestore]);

  const onRegistrationSuccess = (userEmail) => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInAnonymously()
          .then(async () => {
            const result = await firestore
              .collection(COLLECTIONS.STREAMINGS)
              .where('alias', '==', alias)
              .limit(1)
              .get();
            result.forEach((doc) => {
              const userId = firebase.auth().currentUser.uid;
              const userData = { userId, email: userEmail, urlAlias: 'slinda', id: doc.id };

              localStorage.setItem('last-event-url', 'slinda');
              setLogged(userData);
              setIsRegistered(true);
            });
          });
      });
  };

  return (
    <>
      <FontStyles />
      <Theme>
        <Container>
          {!isRegistered ? (
            <PreRegister onRegistrationSuccess={onRegistrationSuccess} />
          ) : (
            <>
              <Header />
              <VideoPlayContext.Provider
                value={{
                  videoModal,
                  setVideo: changeVideoModalStatus
                }}
              >
                <OnDemand />
              </VideoPlayContext.Provider>
              <Speakers />
              <StreamingSection />
              <Contest />
              <LibrarySection />
              <Footer />
            </>
          )}
        </Container>
        <Modal
          isOpen={Boolean(videoModal)}
          onRequestClose={() => setVideoModal(null)}
          style={modalStyle(isMobile)}
        >
          <Wrapper className="player-wrapper" isNotIos={OS !== 'iOS'}>
            <ReactPlayer
              width="auto"
              url={videoModal}
              playing
              controls
              onEnded={() => setVideoModal(null)}
              config={{
                youtube: {
                  playerVars: {
                    rel: 0,
                    showinfo: 0,
                    modestbranding: 1,
                    fs: 0,
                    disablekb: 1,
                    host: 'http://www.youtube.com'
                  }
                }
              }}
            />
          </Wrapper>
        </Modal>
      </Theme>
    </>
  );
};

/*

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000
  }
};

  <Modal isOpen={videoModal} onRequestClose={() => setVideoModal(false)} style={customStyles}>
        <ReactPlayer url="https://gdurl.com/kMFm" playing controls />
      </Modal>
        */
