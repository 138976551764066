import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useFirestore } from 'react-redux-firebase';
import hash from 'object-hash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useLocation } from 'react-router';

import { COLLECTIONS, VALIDATIONS } from '../../constants';
import {
  ThanksRegisterMessage,
  Error,
  RegisterButton,
  ThanksImage,
  LoginWrapper,
  FormWrapper,
  InputContainer,
  Input,
  Label,
  ButtonRow,
  Button
} from './styled';

import CountryInput from '../../components/CountryInput';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Row } from '../../components/Layout';
import useIsMobile from '../../hooks/useIsMobile';

const THANKS = require('./img/thanks.png');

const preRegisterSchema = Yup.object().shape({
  fullName: Yup.string().required(VALIDATIONS.FULLNAME_REQUIRED),
  company: Yup.string().required(VALIDATIONS.COMPANY_REQUIRED),
  charge: Yup.string().required(VALIDATIONS.CHARGE_REQUIRED),
  phone: Yup.string()
    .matches(/^[0-9]*$/, VALIDATIONS.MUST_BE_NUMBERS)
    .required(VALIDATIONS.PHONE_REQUIRED),
  email: Yup.string().email(VALIDATIONS.EMAIL_FORMAT).required(VALIDATIONS.EMAIL_REQUIRED)
});

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER
});
let preRegisterRef;

export default () => {
  const [preRegisterInProgress, setPreRegisterInProgress] = useState();
  const [preRegisterFinish, setPreRegisterFinish] = useState();
  const isMobile = useIsMobile();

  const firestore = useFirestore();
  const location = useLocation();
  const alias = location.pathname.split('/')[1];

  useEffect(() => {
    const init = async () => {
      preRegisterRef = await firestore.collection(COLLECTIONS.PRE_REGISTER).doc(`${alias}`);
      document.title = alias.toLocaleUpperCase();
      const faviconEl = document.getElementById('favicon');
      faviconEl.href =
        'https://icons.iconarchive.com/icons/hopstarter/button/16/Button-Play-icon.png';
    };
    init();
  }, [alias, firestore]);

  const submitPreregister = (values) => {
    setPreRegisterInProgress(true);
    preRegisterRef
      .update({
        registered: firestore.FieldValue.arrayUnion({
          id: hash(Math.random()),
          created: new Date(),
          ...values
        })
      })
      .then(() => {
        setPreRegisterFinish(true);
        setPreRegisterInProgress(false);
      })
      .catch(() => {
        setPreRegisterFinish(true);
        toast.error('Ocurrio un error');
        setPreRegisterInProgress(false);
      });
  };

  const CountryInputComponent = ({ setFieldValue }) => (
    <CountryInput
      name="country"
      onChange={(country) => setFieldValue('country', country)}
      defaultValue={(country) => setFieldValue('country', country)}
      allCountries
    />
  );

  return (
    <>
      {preRegisterFinish && (
        <ThanksRegisterMessage>
          <ThanksImage src={THANKS} alt="thanks" />
        </ThanksRegisterMessage>
      )}

      <Formik
        initialValues={{
          fullName: '',
          company: '',
          charge: '',
          country: '',
          email: ''
        }}
        validationSchema={preRegisterSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={submitPreregister}
      >
        {({ errors, handleSubmit, handleChange, setFieldValue }) => (
          <Row>
            {preRegisterInProgress ? (
              <LoginWrapper>
                <LoadingSpinner size="84px" />
              </LoginWrapper>
            ) : (
              <FormWrapper onSubmit={handleSubmit}>
                <Row>
                  <InputContainer>
                    <Input
                      name="fullName"
                      onChange={handleChange}
                      placeholder="Nombre y apellido"
                    />
                    {errors && errors.fullName && <Error>{errors.fullName}</Error>}
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <Input name="company" onChange={handleChange} placeholder="Empresa" />
                    {errors && errors.company && <Error>{errors.company}</Error>}
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <Input name="charge" onChange={handleChange} placeholder="Cargo" />
                    {errors && errors.charge && <Error>{errors.charge}</Error>}
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer style={{ flexFlow: 'row' }}>
                    <Label style={{ display: 'flex', alignItems: 'center' }}>País</Label>
                    <CountryInputComponent setFieldValue={setFieldValue} />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <Input name="email" onChange={handleChange} placeholder="Mail laboral" />
                    {errors && errors.email && <Error>{errors.email}</Error>}
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <Input name="phone" onChange={handleChange} placeholder="Tel. de contacto" />
                    {errors && errors.phone && <Error>{errors.phone}</Error>}
                  </InputContainer>
                </Row>

                <ButtonRow>
                  <Button type="submit">Registrarse</Button>
                </ButtonRow>
              </FormWrapper>
            )}
          </Row>
        )}
      </Formik>
    </>
  );
};
