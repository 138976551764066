import styled from 'styled-components';
import { Calendar } from 'react-big-calendar';
import { Row, Column } from '../../../components/Layout';

export const InputContainer = styled(Row)`
  align-items: center;
`;

export const Input = styled.input`
  width: ${(props) => props.width || 'auto'};
  border-radius: 5px;
  font-size: 14px;
  min-height: 20px;
  min-width: 250px;
  padding: 2px 0px 5px 5px;
  border-color: whitesmoke;
`;

export const Label = styled.label``;

export const Helper = styled.p`
  font-size: 12px;
  color: white;
`;

export const Result = styled(Row)`
  height: 60px;
  border-radius: 20px;
  background-color: green;
  margin-top: 50px;
  color: white;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

export const FileInputContainer = styled(Row)`
  padding: 0 20px;
`;

export const PickerWrapper = styled(Column)`
  margin: 0px 15px;
`;

export const StyledCalendar = styled(Calendar)`
  .rbc-toolbar button {
    color: white;
  }
`;

export const FormSubTitle = styled.h2`
  position: relative;
  padding: 10px 5px;
  border-bottom: 1px solid white;
  display: inline-block;

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 100%;
    height: 3px;
    background: white;
  }
`;
