/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useIsMobile from '../../../hooks/useIsMobile';
import { Button, ButtonRow } from '../PreRegister/styled';
import { Container } from '../styled';

const BACKGROUND_URL = require('./img/background.png');

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  min-height: 80vh;
  font-size: ${(props) => props.theme.fontSize.medium};
`;

const Title = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 15vmax;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: bolder;
  text-align: center;
`;

const List = styled.ul`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 5rem;
  padding: 0;
  display: inline-block;
  text-align: center;
  list-style-position: inside;
  font-family: 'AvantGardeBook';

  & > li {
    font-weight: 300;
  }
`;

const TripBox = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 45vmax;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.mulberry};
  padding: 1rem;
  font-weight: bolder;
  margin: 5rem;
  text-align: center;
  border-radius: 1rem;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: auto;
    `}
`;

const ContestMessage = styled.p`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  font-weight: bolder;
  margin-top: 2rem;
`;

const StyledButton = styled(Button)`
  width: auto;
  height: auto;
  font-size: ${(props) => props.theme.fontSize.small};
  padding: 1vmax;
  background-color: ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.primaryColor};
  border: none;
  cursor: pointer;
  margin-bottom: 3rem;
`;

export default () => {
  const isMobile = useIsMobile();
  return (
    <StyledContainer imageUrl={BACKGROUND_URL}>
      <Title>
        <div>Sorteo en vivo</div>
      </Title>
      <TripBox isMobile={isMobile}>
        <p>Un Viaje a España para conocer</p>
        <p>
          la planta productora de <span style={{ whiteSpace: 'nowrap' }}>Slinda - León Farma</span>
        </p>
      </TripBox>
      <ContestMessage>Para acceder al sorteo deberá: </ContestMessage>
      <List>
        <li>Visualizar los 4 episodios On Demand</li>
        <li>Responder las trivias de cada episodio</li>
        <li>Presenciar el Encuentro de Expertos en Vivo</li>
      </List>
      <ButtonRow>
        <StyledButton type="button">
          <Link to="/files/SLINDA_Bases-y-Condiciones.pdf" target="_blank" download>
            <div style={{ color: 'white' }}>Ver bases y condiciones</div>
          </Link>
        </StyledButton>
      </ButtonRow>
    </StyledContainer>
  );
};
