/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import multiDownload from 'multi-download';
import { Container, Logo } from '../styled';
import logo from './img/head-icon.png';

import file1 from './files/1.Bleeding-Slinda-vs-desogestrel.pdf';
import file2 from './files/2.hadji2019.pdf';
import file3 from './files/3.kimble2020.pdf';
import file4 from './files/4.Slinda-en-Latam.pdf';
import file6 from './files/6.makabe2017-Endometriosis.pdf';
import file7 from './files/7.Cardiovasculare-safety-of-DRSP.pdf';

const BACKGROUND_URL = require('./img/background.png');

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  min-height: 50vh;
`;

const Title = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 4rem;
  color: ${(props) => props.theme.colors.white};
  text-align: center;

  & > :nth-child(2) {
    display: flex;
    font-size: ${(props) => props.theme.fontSize.large};
    justify-content: center;
  }

  & > :last-child {
    font-family: 'AvantGardeBook';
    font-size: ${(props) => props.theme.fontSize.medium};
    text-align: center;
  }
`;

const DownloadableMaterialBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 10vh;
  font-family: 'AvantGardeDemi';
  & > * {
    width: 40vw;
    margin-top: 8rem;
    padding: 1rem;
    border-radius: 3rem;
    background-color: ${(props) => props.theme.colors.mulberry};
    font-size: ${(props) => props.theme.fontSize.small};
    text-align: center;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  & > a {
    color: ${(props) => props.theme.colors.white};
  }
`;

const downloadFiles = () => {
  multiDownload([file1, file2, file3, file4, file6, file7]);
};

export default () => (
  <StyledContainer imageUrl={BACKGROUND_URL}>
    <Title>
      <Logo src={logo} alt="library-logo" library />
      <div>Biblioteca</div>
      <div>Acceda a descargar materiales científicos de Slinda</div>
    </Title>
    <DownloadableMaterialBox>
      <Link to="/files/Monografia_SLINDA.pdf" target="_blank" download>
        Monografía Slinda
      </Link>
      <div onClick={downloadFiles}>Trabajos científicos</div>
    </DownloadableMaterialBox>
  </StyledContainer>
);
