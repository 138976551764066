/* eslint-disable indent */
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { v4 as uuidv4 } from 'uuid';
import { Container, Logo } from '../styled';

import logo from './img/head-icon.png';
import clock from './img/clock.png';
import calendar from './img/calendar.png';
import useIsMobile from '../../../hooks/useIsMobile';
import Button from '../../../components/Button';
import { UserContext } from '../../../contexts';
import { COLLECTIONS, LOCAL_STORAGE } from '../../../constants';

const BACKGROUND_URL = require('./img/background.png');

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 65vh;
`;

const Title = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 5vh;
  color: ${(props) => props.theme.colors.white};
  text-align: center;

  & > :nth-child(2) {
    font-size: ${(props) => props.theme.fontSize.large};
    font-weight: bolder;
    font-family: 'AvantGardeDemi';
  }

  & > :last-child {
    font-size: ${(props) => props.theme.fontSize.medium};
    font-family: 'AvantGardeBook';
  }
`;

const StreamDate = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.white};
  justify-content: center;
  margin-top: 5vh;
  font-weight: bold;
  width: 50vmax;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 90vw;
    `}

  & > div {
    display: flex;
    flex-flow: row wrap;
    margin: 0 0.5rem;
    justify-content: center;
    align-items: center;
  }

  & > div > div {
    text-align: center;
  }

  & > div > {
    font-size: ${(props) => props.theme.fontSize.small};
    ${({ isMobile }) =>
      isMobile &&
      css`
        font-size: ${(props) => props.theme.fontSize.micro};
      `}
  }

  & > div > :last-child {
    font-size: ${(props) => props.theme.fontSize.medium};
    ${({ isMobile }) =>
      isMobile &&
      css`
        font-size: ${(props) => props.theme.fontSize.small};
      `}
  }
`;

const CountdownWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-width: 50vw;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.mulberry};
  border-radius: 2rem;
  margin-top: 7vh;
  margin-bottom: 5vh;
  padding: 1rem;

  & > div {
    text-align: center;
    margin: 0 2rem;
  }

  & > div > div {
    text-align: center;
    font-size: 1.8vmax;
  }

  & > div > p {
    font-size: 3vmax;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.kobi};
  color: ${(props) => props.theme.secondaryColor};
  border: none;
  cursor: pointer;
  min-width: auto;
  min-height: 1vmax;
  width: 20vmax;
  height: 5vmax;
  padding: 0.7vmax;
  font-size: ${(props) => props.theme.fontSize.medium};
  margin: 1vmax 0;
  border-radius: 2vmax;
`;

const renderer = ({ days, hours, minutes, seconds }) => (
  <CountdownWrapper>
    <div>
      <p>{days}</p>
      <div>Días</div>
    </div>
    <div>
      <p>{hours}</p>
      <div>Horas</div>
    </div>
    <div>
      <p>{minutes}</p>
      <div>Minutos</div>
    </div>
    <div>
      <p>{seconds}</p>
      <div>Segundos</div>
    </div>
  </CountdownWrapper>
);

let reportRef;

export default () => {
  const isNotBigScreen = useMediaQuery({
    maxDeviceWidth: 1100
  });
  const isMobile = useIsMobile();
  const history = useHistory();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const userId = firebase.auth().currentUser.uid;
  const { user } = useContext(UserContext);

  useEffect(() => {
    const init = async () => {
      reportRef = await firestore.collection(COLLECTIONS.REPORTS).doc('slinda');
    };
    init();
  }, []);

  const handleRedirect = () => {
    const loginId = uuidv4();
    sessionStorage.setItem(LOCAL_STORAGE.LOGIN_ID, loginId);

    history.push('/slinda/streaming');
    reportRef.update({
      onlines: firestore.FieldValue.arrayUnion({
        loginId,
        userId,
        created: firestore.Timestamp.now(),
        ...user
      })
    });
  };

  return (
    <StyledContainer imageUrl={BACKGROUND_URL}>
      <Title>
        <Logo src={logo} alt="streaming-logo" isNotBigScreen={isNotBigScreen} />
        <div>Encuentro con los Expertos</div>
        <div>Intercambio en vivo con los expertos Slinda</div>
      </Title>
      <StreamDate isMobile={isMobile}>
        <div>
          <Logo src={calendar} alt="calendar-logo" streaming />
          <div>9 de Junio</div>
        </div>
        <div>
          <Logo src={clock} alt="clock-logo" streaming />
          <div>19.00 hs. (Arg)</div>
        </div>
      </StreamDate>
      <Countdown date={moment('2021-06-09 19:00:00').toISOString()} renderer={renderer} />
      <StyledButton isMobile={isMobile} onClick={handleRedirect}>
        Ir a evento
      </StyledButton>
    </StyledContainer>
  );
};
