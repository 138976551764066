import styled, { css } from 'styled-components';
import { Column, Row } from '../../components/Layout';

export const Entry = styled(Row)`
  width: 85%;
  min-height: 60px;
  border: 1px solid;
  padding: 10px;
  box-shadow: 2px 2px grey;
  margin-bottom: 15px;
  font-size: large !important;
  background: white;
  padding: 10px;
  opacity: 0.9;
  border-radius: 7px;

  > ${Column} {
    padding: 0 15px 0 25px;
  }
`;

export const Title = styled.h1`
  background: white;
  padding: 10px;
  opacity: 0.9;
  border-radius: 7px;
`;

export const Container = styled(Column)`
  min-height: 100vh;
  margin: 20px;
  font-size: large;

  ${({ background }) =>
    background &&
    css`
      background-image: url(${background});
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const Section = styled(Row)`
  padding-top: ${(props) => props.withPadding && '15px'};
  border-bottom: ${(props) => props.withBorder && '1px solid whitesmoke'};
`;
