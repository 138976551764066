/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import useIsMobile from '../../hooks/useIsMobile';
import Form from './form';

const BACKGROUND_URL = require('./img/background.jpg');
const FOOTER_URL = require('./img/footer.png');
const HEADER_URL = require('./img/header.png');
const EXPERIENCE_URL = require('./img/experience.png');

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #000217;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: auto;
`;

const Header = styled.div`
  height: 20vmax;
  width: 100%;
  margin: 0 2vmax;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      background-size: auto;
    `}

  ${({ stream }) =>
    stream &&
    css`
      background-position: left;
    `}
`;

const Footer = styled.div`
  height: 10vmax;
  width: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export default () => {
  const isTabletOrLess = useMediaQuery({
    minDeviceWidth: 320,
    maxDeviceWidth: 1000
  });
  const isMobile = useIsMobile();

  if (isTabletOrLess) {
    return (
      <StyledContainer style={{ justifyContent: 'space-around' }}>
        <Header imageUrl={EXPERIENCE_URL} isMobile={isMobile} stream />
        <Header imageUrl={HEADER_URL} isMobile={isMobile} />
        <Form />
        <Footer imageUrl={FOOTER_URL} />
      </StyledContainer>
    );
  }
  return (
    <StyledContainer imageUrl={BACKGROUND_URL}>
      <Form />
    </StyledContainer>
  );
};
