import React, { useState } from 'react';
import { Formik } from 'formik';
import { useFirestore } from 'react-redux-firebase';
import InputColor from 'react-input-color';

import { Column, Row } from '../../../components/Layout';
import { Confirm } from '../../../components/Icons';
import { COLLECTIONS } from '../../../constants';
import LoadingSpinner from '../../../components/LoadingSpinner';

export default ({ data, callback }) => {
  const [loading, setLoading] = useState(false);

  const firestore = useFirestore();

  const submit = ({ primaryColor, secondaryColor }, { resetForm }) => {
    setLoading(true);
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .doc(data.docId)
      .update({
        colors: { primary: primaryColor, secondary: secondaryColor }
      })
      .then(() => {
        resetForm({});
        callback();
        setLoading(false);
      });
  };

  return (
    <Formik initialValues={{ primaryColor: '#FFF', secondaryColor: '#000' }} onSubmit={submit}>
      {({ values, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} style={{ flex: 1 }}>
          <Row style={{ marginTop: '20px' }}>
            <Column
              style={{
                justifyContent: 'space-around'
              }}
            >
              <Row>
                <p>Color Primario : </p>
              </Row>
              <Row>
                <p>Color Secundario : </p>
              </Row>
            </Column>
            <Column
              style={{
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <Row>
                <InputColor
                  name="primaryColor"
                  initialValue="#F0F"
                  onChange={({ hex }) => setFieldValue('primaryColor', hex)}
                  value={values.primaryColor}
                />
              </Row>
              <Row>
                <InputColor
                  name="secondaryColor"
                  initialValue="#000"
                  onChange={({ hex }) => setFieldValue('secondaryColor', hex)}
                  value={values.secondaryColor}
                />
              </Row>
            </Column>
            <Column>
              <Row style={{ flex: 1 }}>
                <Confirm onClick={handleSubmit} size="2x" style={{ padding: '10px' }} withLabel />
                {loading && (
                  <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingSpinner />
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
        </form>
      )}
    </Formik>
  );
};
