import React, { useEffect, useState, useContext } from 'react';
import hash from 'object-hash';
import styled from 'styled-components';

import { useFirestore } from 'react-redux-firebase';
import { COLLECTIONS, DEVICE } from '../constants';
import { UserContext } from '../contexts';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  border: 1px solid;

  @media ${DEVICE.mobileS} {
    height: 5vmax;
    font-size: 1vmax;
  }

  @media ${DEVICE.tablet} {
    height: 5vmax;
    width: 50vmax;
    font-size: 2vmax;
  }

  @media ${DEVICE.laptopL} {
    height: 3vmax;
    width: 50vmax;
    font-size: 1vmax;
  }
`;

const Input = styled.input`
  display: flex;
  flex-direction: column;
  flex: 4;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-width: 0px;

  padding: 10px;

  &:focus {
    border: none;
    outline: none;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: black;
  color: white;
  margin-bottom: 0px;

  :hover {
    cursor: pointer;
  }
`;

let docRef;

export default () => {
  const {
    user: { fullName, name, firstName, lastName, urlAlias, email }
  } = useContext(UserContext);
  const [question, setQuestion] = useState();
  const [sent, setSent] = useState();
  const firestore = useFirestore();

  useEffect(() => {
    const init = async () => {
      docRef = await firestore.collection(COLLECTIONS.MONITOR).doc(urlAlias);
    };
    init();
  }, [firestore]);

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setSent(false);
      }, 2000);
    }
  }, [sent]);

  const submitText = () => {
    const nameToShow = () => {
      if (fullName) return fullName;
      if (name || firstName) return `${name || firstName} ${lastName}`;
      return email;
    };
    if (question) {
      const time = firestore.Timestamp.now();
      docRef.update({
        questions: firestore.FieldValue.arrayUnion({
          id: hash(Math.random()),
          user: nameToShow(),
          message: question,
          created: time
        })
      });
      setQuestion('');
      setSent(true);
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitText();
    }
  };

  return (
    <Wrapper>
      <Input
        type="text"
        onChange={(e) => setQuestion(e.target.value)}
        value={question}
        onKeyPress={handleOnKeyPress}
      />
      <Label onClick={submitText}>{sent ? 'Enviado!' : 'Enviar'}</Label>
    </Wrapper>
  );
};
