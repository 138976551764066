import React from 'react';
import { useFirebase } from 'react-redux-firebase';

import Login from './StyledAuth';
import { ROUTES } from '../../constants';
import { PageCenteredContent } from '../../components/Layout';

const IMAGE_URL =
  'https://images.pexels.com/photos/633409/pexels-photo-633409.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260';

export default () => {
  const firebase = useFirebase();

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    signInSuccessUrl: ROUTES.PANEL
  };

  return (
    <PageCenteredContent withBackgroundImage={IMAGE_URL}>
      <Login uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </PageCenteredContent>
  );
};
