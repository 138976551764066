/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import LinkSection from './LinkSection';
import BrandingSection from './BrandingSection';
import BannerSection from './BannerSection';
import AliasSection from './AliasSection';
import FillAgenda from './FillAgenda';

import { COLLECTIONS, ROUTES } from '../../../constants';
import { Column, Row } from '../../../components/Layout';
import LoadingSpinner from '../../../components/LoadingSpinner';

import { FormSubTitle } from './styled';
import Button from '../../../components/Button';

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER
});

const Container = styled(Row)`
  background-color: darkgrey;
  padding: 20px;
`;

export default () => {
  const { id } = useParams();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const firestore = useFirestore();
  const history = useHistory();

  const getData = useCallback(() => {
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .where('id', '==', id)
      .limit(1)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          const snapshot = doc.data();
          const agenda =
            snapshot.agenda &&
            snapshot.agenda.length > 0 &&
            snapshot.agenda.map((event) => ({
              ...event,
              start: event.start.toDate(),
              end: event.end.toDate()
            }));
          console.log(snapshot);
          setData({ ...snapshot, docId: doc.id, agenda });
          setLoading(false);
        });
      })
      .catch((e) => {
        console.error(e);
        toast.error('Ocurrio un error');
      });
  }, [id, firestore]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [getData]);

  const handleGoBack = () => history.push(ROUTES.PANEL);

  if (loading) {
    return (
      <Container style={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container>
      <Column style={{ flex: 1 }}>
        <Row style={{ flex: 1 }}>
          <Column>
            <LinkSection data={data} getData={getData} />
            <BannerSection data={data} getData={getData} />
          </Column>
          <Column style={{ paddingRight: '40px' }}>
            <AliasSection data={data} getData={getData} />
            <BrandingSection data={data} getData={getData} />
          </Column>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormSubTitle>Agenda</FormSubTitle>
            </Row>
            <Row style={{ flex: 1 }}>
              {data.docId && <FillAgenda id={data.docId} events={data.agenda} callback={getData} />}
            </Row>
          </Column>
        </Row>
        <Row>
          <Button onClick={handleGoBack}>Volver</Button>
        </Row>
      </Column>
    </Container>
  );
};
