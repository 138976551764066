import styled from 'styled-components';
import { Column, Row } from '../../components/Layout';

export const Container = styled(Column)`
  margin: 20px;
  background-color: darkgrey;
  overflow: scroll;
`;

export const InputContainer = styled(Column)`
  align-items: center;
`;

export const Section = styled(Row)`
  padding-top: ${(props) => props.withPadding && '15px'};
  border-bottom: ${(props) => props.withBorder && '1px solid whitesmoke'};
`;

export const ShowColor = styled.div`
  height: 25px;
  width: 50px;
  background-color: ${(props) => props.color};
  border: 1px solid lightgray;
`;
