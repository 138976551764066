import React from 'react';
import styled, { css } from 'styled-components';
import { faSmileBeam, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '../../Layout';
import { DEVICE } from '../../../constants';

export const InputContainer = styled(Row)`
  flex: 1;
  height: 100%;
  align-items: center;
  padding: 0 10px;
`;

const IconWrapper = styled.div`
  color: gray;
  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export const Input = styled.input`
  width: 100%;
  margin: 0px 15px 0px 15px;
  outline: none;
  border: none;
  font-size: smaller;
  font-weight: 300;
  background-color: transparent;
  color: white;

  &:focus {
    padding-bottom: 5px;
    border-bottom: 2px solid dodgerblue;
  }

  @media ${DEVICE.mobileS} {
    color: black;

    &:focus {
      padding-bottom: 1px;
      border-bottom: 1px solid dodgerblue;
    }
  }
`;

export const SmileIcon = ({ onClick, size }) => (
  <IconWrapper onClick={onClick}>
    <FontAwesomeIcon icon={faSmileBeam} size={size} />
  </IconWrapper>
);

export const SendIcon = ({ onClick, size }) => (
  <IconWrapper onClick={onClick} style={{ marginLeft: 'auto', marginRight: '10px' }}>
    <FontAwesomeIcon icon={faChevronCircleRight} size={size} />
  </IconWrapper>
);
