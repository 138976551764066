import React from 'react';
import { Picker } from 'emoji-mart';
import { useLocation } from 'react-router';
import onClickOutside from 'react-onclickoutside';

import 'emoji-mart/css/emoji-mart.css';
import useUrlAlias from '../../hooks/useUrlAlias';
import { NEW_SAP_EVENT } from '../../constants';

let boxStyle;

const ChatPicker = ({ onEmojiClick, tooglePicker }) => {
  const location = useLocation();
  const alias = location.pathname.split('/')[1];

  ChatPicker.handleClickOutside = () => tooglePicker(false);
  if (alias === NEW_SAP_EVENT) {
    boxStyle = {
      position: 'absolute',
      bottom: '0px',
      right: '260px',
      zIndex: '2'
    };
  } else {
    boxStyle = { position: 'absolute', bottom: '20px', left: '50px', zIndex: '2' };
  }

  return (
    <Picker onSelect={(emoji) => onEmojiClick(emoji)} set="apple" theme="dark" style={boxStyle} />
  );
};

ChatPicker.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: () => ChatPicker.handleClickOutside
};

export default onClickOutside(ChatPicker, clickOutsideConfig);
