import React, { useContext } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { CurrentPlayingLink } from '../../contexts';
import { ZoomBrand } from '../../components/Icons';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  background-color: transparent;
  color: ${(props) => props.theme.colors.white};
  opacity: 0.9;
  padding: 15px 0;
  justify-content: space-around;
  align-items: center;
`;

export default ({ data }) => {
  const { setCurrentPlayingLink } = useContext(CurrentPlayingLink);

  return (
    <Container>
      {data.map((room, i) => (
        <>
          <div data-tip data-for={`${room}${i}`}>
            <ZoomBrand onClick={() => setCurrentPlayingLink(room)} />
          </div>
          <ReactTooltip id={`${room}${i}`} place="top" effect="float">
            {`Unirse a una sala ${i + 1}`}
          </ReactTooltip>
        </>
      ))}
    </Container>
  );
};
