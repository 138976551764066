/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { COLLECTIONS, NEW_SAP_EVENT } from '../../constants';
import { CurrentPlayingLink } from '../../contexts';

import { Row } from '../Layout';
import LoadingSpinner from '../LoadingSpinner';

const ESPAÑOL = require('../../screens/Sap/img/sap-germany/boton_español.png');
const ORIGINAL = require('../../screens/Sap/img/sap-germany/boton_original.png');
const LABEL_IDIOMA = require('../../screens/Sap/img/sap-germany/txt_idioma.png');

const Image = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export default () => {
  const [rooms, setRooms] = useState();
  const [loading, setLoading] = useState(true);
  const { setCurrentPlayingLink } = useContext(CurrentPlayingLink);
  const firestore = useFirestore();
  useEffect(() => {
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .doc(NEW_SAP_EVENT)
      .get()
      .then((result) => {
        if (result.exists) {
          const data = result.data();
          setRooms({ original: data.originalLink, spain: data.spainLink });
          setCurrentPlayingLink(data.originalLink);
          setLoading(false);
        } else {
          toast.error('Ocurrio un error');
        }
      });
  }, []);

  if (loading) {
    return (
      <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
        <LoadingSpinner />
      </Row>
    );
  }
  return (
    <Row>
      <Image src={LABEL_IDIOMA} alt="label" />
      <Image src={ORIGINAL} alt="original" onClick={() => setCurrentPlayingLink(rooms.original)} />
      <Image src={ESPAÑOL} alt="español" onClick={() => setCurrentPlayingLink(rooms.spain)} />
    </Row>
  );
};
