/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css } from 'styled-components';
import { Container } from '../styled';

const BACKGROUND_URL = require('./img/background_2.png');
const PACK = require('./img/pack.png');
const SINTACC = require('./img/sintacc.png');

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: row wrap;
  height: 55vh;
  justify-content: space-around;
  align-items: center;
  background-position: bottom;
  font-family: 'AvantGardeBook';

  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: 400;
  & > div {
    display: flex;
    flex-flow: column;
    color: ${(props) => props.theme.secondaryColor};
    text-align: center;
  }

  & > div > p {
    margin: 0;
  }

  & > div > p > span {
    color: ${(props) => props.theme.colors.mulberry};
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-flow: column;
      justify-content: space-around;
      align-items: center;
      background-size: center;
      height: 30vmax;
    `}
`;

const Pack = styled.img`
  width: 30vmax;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 25vmax;
    `}
`;

const SinTacc = styled.img`
  position: relative;
  top: -3.5vmax;
  left: -2vmax;
  width: 4vmax;
`;

export default () => {
  const isMobile = useMediaQuery({
    minDeviceWidth: 320,
    maxDeviceWidth: 1000
  });

  if (isMobile) {
    return (
      <StyledContainer imageUrl={BACKGROUND_URL} isMobile={isMobile}>
        <div>
          <Pack src={PACK} isMobile={isMobile} />
          <SinTacc src={SINTACC} />
        </div>
      </StyledContainer>
    );
  }
  return (
    <StyledContainer imageUrl={BACKGROUND_URL}>
      <div style={{ marginTop: '-15vmax' }}>
        <p>Primer anticonceptivo oral</p>
        <p>
          <span>libre de estrógenos</span> sólo con
        </p>
        <p>
          <span>Drospirenona.</span>
        </p>
      </div>
      <div>
        <Pack src={PACK} />
        <SinTacc src={SINTACC} />
      </div>
      <div style={{ marginTop: '-15vmax' }}>
        <p>
          <span>Segura como siempre,</span>
        </p>
        <p>libre como nunca.</p>
      </div>
    </StyledContainer>
  );
};
