import React from 'react';
import { ThemeProvider } from 'styled-components';

const mercury = '#e8e8e8';
const malibu = '#56ACF9';

const theme = {
  primaryColor: malibu,
  lightGrayBackground: mercury,
  colors: {
    mercury,
    malibu,
    white: '#FFFFFF',
    black: '#000000',
    orangePeel: '#FF9800',
    aliceblue: 'aliceblue',
    bigStone: '#1b3043'
  },
  fonts: ['sans-serif', 'Roboto'],
  fontSizes: {
    small: '0.8em',
    medium: '1em',
    large: '2em'
  }
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
