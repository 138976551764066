/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-confusing-arrow */
import React, { useState, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import Login from './screens/Login';
import Welcome from './screens/Welcome';
import Panel from './screens/Panel';
import Streaming from './screens/Stream';
import Form from './screens/Panel/TransmisionForm/Form';

import { LOCAL_STORAGE, ROUTES } from './constants';
import { PageCenteredContent } from './components/Layout';
import LoadingSpinner from './components/LoadingSpinner';
import { UserContext } from './contexts';
import Landing from './screens/Landing';
import Monitor from './screens/Monitor';
import PreRegister from './screens/PreRegister';
import TecnicaForm from './screens/TecnicaForm';
import ModeradorQuestions from './screens/Moderador/ModeradorQuestions';
import ModeradorChat from './screens/Moderador/ModeradorChat';
import CargaDatos from './screens/CargaDatos';

const PublicRestritedRoute = ({ children, ...rest }) => {
  const { user } = useContext(UserContext);
  const redirectWorkaround = () => {
    const url = localStorage.getItem(LOCAL_STORAGE.LAST_EVENT_URL);
    return url ? (
      <Redirect
        to={{
          pathname: `/${url}`
        }}
      />
    ) : (
      <Redirect
        to={{
          pathname: ROUTES.WELCOME
        }}
      />
    );
  };
  return <Route {...rest} render={() => (user ? children : redirectWorkaround())} />;
};

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) {
    return (
      <PageCenteredContent>
        <LoadingSpinner size={100} />
      </PageCenteredContent>
    );
  }

  if (isLoaded(auth) && !isEmpty(auth)) {
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Redirect
      {...rest}
      to={{
        pathname: ROUTES.LOGIN
      }}
    />
  );
};

export default () => {
  const [loggedUser, setLoggedUser] = useState(null);
  return (
    <Switch>
      <Route exact path="/carga">
        <CargaDatos />
      </Route>

      <PrivateRoute path={ROUTES.PANEL}>
        <Panel />
      </PrivateRoute>

      <Route exact path={ROUTES.LOGIN}>
        <Login />
      </Route>

      <Route exact path={`/:alias${ROUTES.PREREGISTER}`}>
        <PreRegister />
      </Route>

      <Route exact path="/:alias/moderador/questions">
        <ModeradorQuestions />
      </Route>

      <Route exact path="/:alias/moderador/chat">
        <ModeradorChat />
      </Route>

      <Route exact path="/:alias/:speaker/questions">
        <Monitor />
      </Route>

      <PrivateRoute path={`${ROUTES.EDIT}/:id`}>
        <Form />
      </PrivateRoute>

      <PrivateRoute path={`${ROUTES.TECNICA}/:id`}>
        <TecnicaForm />
      </PrivateRoute>

      <UserContext.Provider value={{ user: loggedUser, setLogged: setLoggedUser }}>
        <Route exact path="/">
          <Landing />
        </Route>

        <Route exact path="/:alias">
          <Welcome />
        </Route>

        <PublicRestritedRoute path="/:alias/streaming">
          <Streaming />
        </PublicRestritedRoute>
        {/* 
      
        <Route path="/:alias">
          <Streaming />
        </Route>
        */}
      </UserContext.Provider>
    </Switch>
  );
};
