import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useContext } from 'react';

import { COLLECTIONS } from '../constants';
import { UserContext } from '../contexts';

export default () => {
  const {
    user: { id }
  } = useContext(UserContext);

  const getFromState = (state) =>
    state.firestore.ordered[COLLECTIONS.STREAMINGS] &&
    state.firestore.ordered[COLLECTIONS.STREAMINGS][0] &&
    state.firestore.ordered[COLLECTIONS.STREAMINGS][0];

  useFirestoreConnect(() => [{ collection: COLLECTIONS.STREAMINGS, doc: id }]);
  const data = useSelector((state) => getFromState(state));
  console.log('Stream data: ', data);
  return data;
};
