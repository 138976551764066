import React from 'react';
import { Modal } from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';
import './styles.css';

export default ({ showCloseIcon, visible, children, onClose }) => (
  <Modal
    open={visible}
    onClose={onClose}
    showCloseIcon={showCloseIcon}
    styles={{
      modal: {
        animation: `${visible ? 'customEnterAnimation' : 'customLeaveAnimation'} 500ms`
      }
    }}
  >
    {children}
  </Modal>
);
