/* eslint-disable react/jsx-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';

import { Section, Container, Title, Entry } from './styled';
import { Column, Row } from '../../components/Layout';
import Button from '../../components/Button';

const BACKGROUND = require('../../assets/checklist.jpeg');

const selectStyles = {
  menu: () => ({
    background: 'lightgrey',
    border: 'black'
  })
};

export default ({ entries, speakers, modulo, onApprove }) => {
  const [speaker, setSpeaker] = useState();

  useEffect(() => {
    if (speakers && speakers.length > 0) setSpeaker(speakers[0]);
  }, [speakers]);
  return (
    <Container background={BACKGROUND}>
      <Section>
        <Column>
          <Row style={{ justifyContent: 'center', padding: '2rem' }}>
            <Title>{`Moderación ${modulo}`}</Title>
          </Row>
          <Row>
            <Column style={{ alignItems: 'center', padding: '2rem' }}>
              {entries.map((entry) => {
                const { id, message, created, user } = entry;
                const time = moment(created).format('hh:mm A');
                return (
                  <Entry key={id}>
                    <Column
                      style={{
                        flex: 3,
                        wordBreak: 'break-all',
                        paddingRight: '5px',
                        minWidth: '200px'
                      }}
                    >
                      {message}
                    </Column>
                    <Column style={{ padding: '0 15px 0 25px', alignContent: 'center' }}>
                      {user}
                    </Column>
                    <Column>{time}</Column>

                    {speakers && speakers.length > 0 && (
                      <Column>
                        <Select
                          styles={selectStyles}
                          value={{ value: speakers[0], label: speakers[0] }}
                          onChange={(option) => setSpeaker(option)}
                          options={speakers.map((item) => ({ value: item, label: item }))}
                        />
                      </Column>
                    )}
                    <Column style={{ justifyContent: 'center' }}>
                      {!entry.approved ? (
                        <Button
                          style={{ backgroundColor: 'lightGreen', fontSize: 'large' }}
                          onClick={() =>
                            speaker ? onApprove({ ...entry, speaker }) : onApprove(entry)
                          }
                        >
                          Aprobar
                        </Button>
                      ) : (
                        <p>Aprobada</p>
                      )}
                    </Column>
                  </Entry>
                );
              })}
            </Column>
          </Row>
        </Column>
      </Section>
    </Container>
  );
};
