export const COLLECTIONS = {
  STREAMINGS: '/streamings',
  LOGOS: '/logos',
  BACKGROUNDS: '/backgrounds',
  BANNERS: '/banners',
  STREAM_BACKGROUNDS: '/stream-backgrounds',
  COUNTDOWNS: '/countdowns',
  UPPER_LOGOS: '/upper-logos',
  REPORTS: '/reports',
  MONITOR: '/monitor',
  PRE_REGISTER: '/preregister',
  ORADORES: '/oradores',
  QUESTIONS: '/questions',
  MODERATE_CHAT: '/moderate-chat'
};

export const ROUTES = {
  LOGIN: '/login',
  STREAM: '/stream',
  PANEL: '/panel',
  EDIT: '/edit',
  PREREGISTER: '/registro',
  TECNICA: '/tecnica',
  MONITOR: '/monitor'
};

export const VALIDATIONS = {
  FULLNAME_REQUIRED: 'Ingrese su nombre completo',
  NAME_REQUIRED: 'Ingrese nombre',
  LASTNAME_REQUIRED: 'Ingrese apellido',
  PASSWORD_REQUIRED: 'Ingrese contraseña',
  EMAIL_FORMAT: 'Ingrese correo válido',
  EMAIL_REQUIRED: 'Ingrese correo',
  LINK_REQUIRED: 'Ingresar link de transmisión',
  ALIAS_REQUIRED: 'Ingresar alias de transmisión',
  COMPANY_REQUIRED: 'Ingresar la compañia ',
  CHARGE_REQUIRED: 'Ingresar el cargo',
  PHONE_REQUIRED: ' Ingresar número telefónico',
  MUST_BE_NUMBERS: 'Deben ser números',
  FIELD_REQUIRED: 'Campo requerido'
};

export const ACCESS_DATA = 'ACCESS_DATA';

export const DEVICE_SIZE = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '1920px'
};

export const DEVICE = {
  mobileS: `(min-width: ${DEVICE_SIZE.mobileS})`,
  mobileM: `(min-width: ${DEVICE_SIZE.mobileM})`,
  mobileL: `(min-width: ${DEVICE_SIZE.mobileL})`,
  tablet: `(min-width: ${DEVICE_SIZE.tablet})`,
  laptop: `(min-width: ${DEVICE_SIZE.laptop})`,
  laptopL: `(min-width: ${DEVICE_SIZE.laptopL})`,
  desktop: `(min-width: ${DEVICE_SIZE.desktop})`
};

export const getOS = () => {
  const { userAgent, platform } = window.navigator;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const SAP_URL = 'sap-pkom2021';
export const NEW_SAP_EVENT = 'sap-made-in-germany';
export const TIENDA_DIGGIT_EVENT = 'tiendadiggit';
export const EMAIL_ADMIN = 'avglive.developer@gmail.com';
export const LOCAL_STORAGE = {
  LAST_EVENT_URL: 'last-event-url',
  LOGIN_ID: 'login-id'
};

export const SLINDA_URL = 'slinda';
export const STREAM_STUDIO_SRL = 'streamstudio';
