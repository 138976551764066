/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import hash from 'object-hash';
import { useMediaQuery } from 'react-responsive';

import { Container, Logo } from '../styled';

import logo from './img/head-icon.png';
import Item from './Item';

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column;
  min-height: 135vh;
`;

const Title = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: ${(props) => props.theme.fontSize.large};
  text-align: center;
  align-items: center;
  font-family: 'AvantGardeDemi';

  & > :last-child {
    display: flex;
    font-size: ${(props) => props.theme.fontSize.large};
    color: ${(props) => props.theme.secondaryColor};
    justify-content: center;
  }
`;

const OnDemandBox = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 3rem 2rem 0rem 2rem;

  & > div {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    justify-content: space-evenly;
  }
`;

const PALACIOS_PREVIEW = require('./img/palacios.png');
const FUSARO_PREVIEW = require('./img/fusaro.png');
const LABOVSKY_PREVIEW = require('./img/labovsky.png');
const REGIDOR_PREVIEW = require('./img/regidor.png');

const entries = [
  {
    title: 'Episodio 1',
    trivia: 'https://encuesta.netlify.app/slinda-trivia-1',
    video: 'https://youtu.be/hy-l8RAuRlY',
    preview: REGIDOR_PREVIEW
  },
  {
    title: 'Episodio 2',
    trivia: 'https://encuesta.netlify.app/slinda-trivia-2',
    video: 'https://youtu.be/4B-exL4QQbg',
    preview: PALACIOS_PREVIEW
  },
  {
    title: 'Episodio 3',
    trivia: 'https://encuesta.netlify.app/slinda-encuesta-1',
    video: 'https://youtu.be/hZBmwmfEkP8',
    preview: FUSARO_PREVIEW
  },
  {
    title: 'Episodio 4',
    trivia: 'https://encuesta.netlify.app/slinda-encuesta-2',
    video: 'https://youtu.be/M1n_mYdDGwY',
    preview: LABOVSKY_PREVIEW
  }
];

export default React.memo(() => {
  const isNotBigScreen = useMediaQuery({
    maxDeviceWidth: 1350
  });
  return (
    <StyledContainer>
      <Title>
        <Logo src={logo} alt="onDemand-logo" isNotBigScreen={isNotBigScreen} />
        <div>Contenidos On Demand</div>
      </Title>
      <OnDemandBox>
        <div>
          {[entries[0], entries[1]].map((entry) => (
            <Item key={hash(Math.random())} data={entry} />
          ))}
        </div>
        <div>
          {[entries[2], entries[3]].map((entry) => (
            <Item key={hash(Math.random())} data={entry} />
          ))}
        </div>
      </OnDemandBox>
    </StyledContainer>
  );
});
