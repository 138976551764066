/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { COLLECTIONS } from '../../../constants';
import { Column, Row } from '../../../components/Layout';
import LoadingSpinner from '../../../components/LoadingSpinner';
import Table from '../../../components/Table';
import columns from './columns';

export default () => {
  const [streamings, setStreamings] = useState(null);
  const [loading, setLoading] = useState(false);
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebase.auth);

  useEffect(() => {
    const data = [];
    setLoading(true);
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          if (doc.data()) {
            const snapshot = doc.data();
            const agenda =
              snapshot.agenda &&
              snapshot.agenda.map((event) => ({
                ...event,
                start: moment(event.start.toDate()).format('hh:mm A'),
                end: moment(event.end.toDate()).format('hh:mm A')
              }));

            const newData = { ...snapshot, agenda };
            data.push({ docId: doc.id, ...newData });
          }
        });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setStreamings(data);
        setLoading(false);
      });
  }, [auth.uid, firestore]);

  if (loading) {
    return (
      <Column>
        <Row style={{ justifyContent: 'center' }}>
          <LoadingSpinner />
        </Row>
      </Column>
    );
  }

  return (
    <Column>
      <Table data={streamings || []} columns={columns} />
    </Column>
  );
};
