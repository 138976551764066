import React from 'react';
import { Row, Column } from '../../../components/Layout';
import { FormSubTitle } from './styled';
import AliasForm from './AliasForm';

export default ({ getData, data }) => (
  <>
    <Row>
      <FormSubTitle>Alias</FormSubTitle>
    </Row>
    <Row>
      {data.alias && (
        <Column>
          <Row style={{ alignItems: 'center' }}>
            <h4>Alias actual: </h4>
            <div style={{ marginLeft: '10px' }}>{data.alias}</div>
          </Row>
        </Column>
      )}
    </Row>
    {data.docId && (
      <Row>
        <AliasForm docId={data.docId} callback={getData} />
      </Row>
    )}
  </>
);
