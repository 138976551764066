import React, { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import hash from 'object-hash';
import faker from 'faker/locale/es';
import { COLLECTIONS } from '../../constants';
import { Workbook } from 'react-excel-workbook';
import Button from '../../components/Button';
import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY hh:mm:ss A';

export default () => {
  const firestore = useFirestore();
  const [onlineData, setOnlineData] = useState();

  useEffect(() => {
    firestore
      .collection(COLLECTIONS.PRE_REGISTER)
      .where('alias', '==', 'slinda')
      .limit(1)
      .get()
      .then((registradosResult) => {
        registradosResult.forEach((doc) => {
          const registrados = doc.data().registered;
          firestore
            .collection(COLLECTIONS.REPORTS)
            .doc('slinda')
            .get()
            .then((onlinesResult) => {
              const { onlines } = onlinesResult.data();
              const result = onlines
                .map((online) => {
                  const object = registrados.find(
                    (registrado) => registrado.email === online.email
                  );
                  const mergedOnline = {
                    ...online,
                    ...object,
                    created: online.created.toDate(),
                    disconnectAt: online.disconnectAt && online.disconnectAt.toDate()
                  };
                  return mergedOnline;
                })
                .sort((a, b) => b.created - a.created);
              setOnlineData(result);
            });
        });
      });
  }, []);

  console.log(onlineData);
  return (
    <Workbook
      filename="Onlines-slinda.xlsx"
      element={<Button style={{ width: 'auto' }}>Descargar onlines!</Button>}
    >
      <Workbook.Sheet data={onlineData} name="Onlines">
        <Workbook.Column label="Nombre" value="name" />
        <Workbook.Column label="Apellido" value="lastName" />
        <Workbook.Column label="Provincia" value="province" />
        <Workbook.Column label="Localidad" value="state" />
        <Workbook.Column label="Matrícula" value="registrationId" />
        <Workbook.Column label="Email" value="email" />
        <Workbook.Column
          label="Ingreso"
          value={(row) => moment(row.created).format(DATE_FORMAT) || ''}
        />
        <Workbook.Column
          label="Egreso"
          value={(row) => (row.disconnectAt ? moment(row.disconnectAt).format(DATE_FORMAT) : '')}
        />
        <Workbook.Column label="Cantidad en linea" value="quantity" />
      </Workbook.Sheet>
    </Workbook>
  );
};
