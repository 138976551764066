import React from 'react';
import { Row, Column } from '../../../components/Layout';
import Thumbnail from '../../../components/Thumbnail';
import FirebaseFileInput from '../../../components/FirebaseFileInput';

import useLogos from '../../../hooks/useLogos';
import useBackground from '../../../hooks/useBackground';

import { COLLECTIONS } from '../../../constants';
import { FormSubTitle } from './styled';
import useBanner from '../../../hooks/useBanner';
import useStreamBackground from '../../../hooks/useStreamBackground';
import useUpperLogo from '../../../hooks/useUpperLogo';

export default ({ data, getData }) => {
  const { loadingLogos, logos } = useLogos(data.alias);
  const { loadingBackground, background } = useBackground(data.alias);
  const { loadingStreamBackground, streamBackground } = useStreamBackground(data.alias);
  const { loadingUpperLogo, upperLogo } = useUpperLogo(data.alias);

  const banner = useBanner(data.alias);

  return (
    <Column>
      <Row>
        <FormSubTitle>Banners</FormSubTitle>
      </Row>
      <Row>
        {banner && (
          <Column>
            <Row>
              <h4>Archivos actuales</h4>
            </Row>
            <Row>
              <Thumbnail key={banner.id} src={banner.url} alt="banner" />
            </Row>
          </Column>
        )}
      </Row>
      <Row>
        {data.docId && (
          <FirebaseFileInput
            folder="banners"
            id={data.docId}
            alias={data.alias}
            collection={COLLECTIONS.BANNERS}
            onFinish={getData}
          />
        )}
      </Row>
      <Row>
        <FormSubTitle>Logos</FormSubTitle>
      </Row>
      <Row>
        {!loadingLogos && (
          <Column>
            <Row>
              <h4>Archivos actuales</h4>
            </Row>
            <Row>
              {logos.map(({ url, id: logoId }) => (
                <Thumbnail key={logoId} src={url} alt="logo" />
              ))}
            </Row>
          </Column>
        )}
      </Row>
      <Row>
        {data.docId && (
          <FirebaseFileInput
            folder="logos"
            id={data.docId}
            alias={data.alias}
            collection={COLLECTIONS.LOGOS}
            onFinish={getData}
          />
        )}
      </Row>

      <Row>
        <FormSubTitle>Background</FormSubTitle>
      </Row>
      <Row>
        {!loadingBackground && (
          <Column>
            <Row>
              <h4>Archivos actuales</h4>
            </Row>
            <Row>
              {background && (
                <Thumbnail key={background.id} src={background.url} alt="background" />
              )}
            </Row>
          </Column>
        )}
      </Row>
      <Row>
        {data.docId && (
          <FirebaseFileInput
            folder="backgrounds"
            id={data.docId}
            alias={data.alias}
            collection={COLLECTIONS.BACKGROUNDS}
            onFinish={getData}
          />
        )}
      </Row>

      <Row>
        <FormSubTitle>Stream Background</FormSubTitle>
      </Row>
      <Row>
        {!loadingStreamBackground && (
          <Column>
            <Row>
              <h4>Archivos actuales</h4>
            </Row>
            <Row>
              {streamBackground && (
                <Thumbnail key={streamBackground.id} src={streamBackground.url} alt="background" />
              )}
            </Row>
          </Column>
        )}
      </Row>
      <Row>
        {data.docId && (
          <FirebaseFileInput
            folder="stream-backgrounds"
            id={data.docId}
            alias={data.alias}
            collection={COLLECTIONS.STREAM_BACKGROUNDS}
            onFinish={getData}
          />
        )}
      </Row>

      <Row>
        <FormSubTitle>Upper logo</FormSubTitle>
      </Row>
      <Row>
        {!loadingUpperLogo && (
          <Column>
            <Row>
              <h4>Archivos actuales</h4>
            </Row>
            <Row>
              {upperLogo && <Thumbnail key={upperLogo.id} src={upperLogo.url} alt="upperLogo" />}
            </Row>
          </Column>
        )}
      </Row>
      <Row>
        {data.docId && (
          <FirebaseFileInput
            folder="upper-logo"
            id={data.docId}
            alias={data.alias}
            collection={COLLECTIONS.UPPER_LOGOS}
            onFinish={getData}
          />
        )}
      </Row>
    </Column>
  );
};
