/* eslint-disable function-paren-newline */
import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Countdown from 'react-countdown';

import * as Yup from 'yup';

import { Row, PageCenteredContent, WelcomePage, Column } from '../../components/Layout';
import Button from '../../components/Button';
import LoadingSpinner from '../../components/LoadingSpinner';

import CountryInput from '../../components/CountryInput';
import { UserContext } from '../../contexts';

import {
  FormWrapper,
  InputContainer,
  Input,
  Label,
  LoginWrapper,
  Error,
  Logo,
  LogoWrapper,
  CountdownWrapper,
  ButtonRow,
  CountdownRow
} from './styled';
import {
  COLLECTIONS,
  NEW_SAP_EVENT,
  SAP_URL,
  TIENDA_DIGGIT_EVENT,
  VALIDATIONS,
  SLINDA_URL,
  STREAM_STUDIO_SRL
} from '../../constants';

import useLogos from '../../hooks/useLogos';
import useUrlAlias from '../../hooks/useUrlAlias';
import useBackground from '../../hooks/useBackground';
import useCountdown from '../../hooks/useCountdown';
import useIsMobile from '../../hooks/useIsMobile';
import useUpperLogo from '../../hooks/useUpperLogo';
import { EyeOpen, EyeClosed } from '../../components/Icons';
import SapScreen from '../Sap/SapScreen';
import Slinda from '../Slinda';
import AvliveRegister from '../AvliveRegister';

const IMAGE_URL = require('../../assets/welcome.png');

const schema = Yup.object().shape({
  name: Yup.string().required(VALIDATIONS.NAME_REQUIRED),
  email: Yup.string().email(VALIDATIONS.EMAIL_FORMAT).required(VALIDATIONS.EMAIL_REQUIRED),
  lastName: Yup.string().required(VALIDATIONS.LASTNAME_REQUIRED),
  password: Yup.string().required(VALIDATIONS.PASSWORD_REQUIRED)
});

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: toast.POSITION.BOTTOM_CENTER
});

let reportRef;

export default () => {
  const [redirect, setRedirect] = useState(null);
  const [loginIn, setLoginIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setLogged } = useContext(UserContext);

  const { loadingLogos, logos } = useLogos();
  const { loadingBackground, background } = useBackground();
  const { loadingUpperLogo, upperLogo } = useUpperLogo();
  const { loadingCountdown, startAt } = useCountdown();

  const alias = useUrlAlias();

  const firestore = useFirestore();
  const firebase = useFirebase();
  const isMobile = useIsMobile();

  useEffect(() => {
    const init = async () => {
      reportRef = await firestore.collection(COLLECTIONS.REPORTS).doc(`${alias}`);
    };
    init();
  }, [alias, firestore]);

  if (alias === STREAM_STUDIO_SRL) {
    return <AvliveRegister />;
  }

  if (alias === SLINDA_URL) {
    return <Slinda />;
  }

  const submit = async ({ name, lastName, email, password }) => {
    setLoginIn(true);
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInAnonymously()
          .then(async () => {
            const result = await firestore
              .collection(COLLECTIONS.STREAMINGS)
              .where('sharedPassword', '==', password)
              .where('alias', '==', alias)
              .limit(1)
              .get();
            if (!result || result.size === 0) {
              toast.error('Contraseña incorrecta!');
              setLoginIn(false);
            } else {
              result.forEach((doc) => {
                const userId = firebase.auth().currentUser.uid;
                const userData = { userId, name, lastName, email };
                setTimeout(() => {
                  setLogged({
                    id: doc.id,
                    urlAlias: alias,
                    ...userData
                  });
                  reportRef.update({
                    onlines: firestore.FieldValue.arrayUnion({
                      userId,
                      created: firestore.Timestamp.now(),
                      ...userData
                    })
                  });
                  localStorage.setItem('last-event-url', alias);
                  setRedirect(true);
                  setLoginIn(false);
                }, 1500);
              });
            }
          });
      });
  };

  const CountryInputComponent = ({ setFieldValue }) => (
    <CountryInput
      name="country"
      onChange={(country) => setFieldValue('country', country)}
      defaultValue={(country) => setFieldValue('country', country)}
      color="steelblue"
    />
  );

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: `/${alias}/streaming`
        }}
      />
    );
  }

  if (loadingLogos || loadingUpperLogo || loadingBackground || loadingCountdown) {
    return (
      <LazyLoadComponent>
        <PageCenteredContent
          withBackgroundImage={alias === 'welcome' ? IMAGE_URL : background && background.url}
        >
          <LoadingSpinner />
        </PageCenteredContent>
      </LazyLoadComponent>
    );
  }

  if (alias === SAP_URL || alias === NEW_SAP_EVENT) {
    return <SapScreen noFooter={alias === NEW_SAP_EVENT} />;
  }
  const countdownRenderer = ({ days, hours, minutes }) => (
    <Row style={{ width: '100%' }}>
      <Column>
        <CountdownRow title>Días</CountdownRow>
        <CountdownRow value>{days}</CountdownRow>
      </Column>
      <Column>
        <CountdownRow title>Horas</CountdownRow>
        <CountdownRow value>{hours}</CountdownRow>
      </Column>
      <Column>
        <CountdownRow title>Minutos</CountdownRow>
        <CountdownRow value>{minutes}</CountdownRow>
      </Column>
    </Row>
  );

  return (
    <WelcomePage
      isMobile={isMobile}
      withBackgroundImage={(background && background.url) || IMAGE_URL}
      tiendaDiggitEvent={alias === TIENDA_DIGGIT_EVENT}
    >
      {upperLogo && (
        <LogoWrapper>
          <Logo key={upperLogo.id} id={upperLogo.id} url={upperLogo.url} upperLogo />
        </LogoWrapper>
      )}
      {loginIn ? (
        <LoginWrapper
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            minHeight: '350px',
            backgroundColor: 'transparent'
          }}
        >
          <LoadingSpinner size="84px" />
        </LoginWrapper>
      ) : (
        <>
          {startAt && (
            <LoginWrapper id="countdownContainer" tiendaDiggitEvent={alias === TIENDA_DIGGIT_EVENT}>
              <CountdownWrapper>
                <p>Virtual Diggit</p>
                <Countdown date={startAt.toDate()} renderer={countdownRenderer} />
              </CountdownWrapper>
            </LoginWrapper>
          )}
          <LoginWrapper tiendaDiggitEvent={alias === TIENDA_DIGGIT_EVENT}>
            <Formik
              initialValues={{ name: '', lastName: '', password: '', country: '', email: '' }}
              validationSchema={schema}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={submit}
            >
              {({ errors, handleSubmit, handleChange, setFieldValue }) => (
                <Row>
                  <FormWrapper onSubmit={handleSubmit}>
                    <Row style={{ justifyContent: 'center' }}>
                      {alias === TIENDA_DIGGIT_EVENT && <h2>Bienvenidos a Virtual Diggit</h2>}
                    </Row>
                    <Row>
                      <InputContainer style={{ marginRight: '10px' }}>
                        <Label>Nombre</Label>
                        <Input name="name" onChange={handleChange} />
                        {errors && errors.name && <Error>{errors.name}</Error>}
                      </InputContainer>

                      <InputContainer style={{ marginLeft: '10px' }}>
                        <Label>Apellido</Label>
                        <Input name="lastName" onChange={handleChange} />
                        {errors && errors.lastName && <Error>{errors.lastName}</Error>}
                      </InputContainer>
                    </Row>
                    <Row>
                      <InputContainer style={{ marginRight: '10px' }}>
                        <Label>Email</Label>
                        <Input name="email" onChange={handleChange} />
                        {errors && errors.email && <Error>{errors.email}</Error>}
                      </InputContainer>
                    </Row>
                    <Row>
                      <Column>
                        <Row>
                          <InputContainer>
                            <Label>Contraseña</Label>
                            <Column style={{ flex: 1 }}>
                              <Input
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                autocomplete="new-password"
                              />
                            </Column>
                          </InputContainer>
                          <Column
                            style={{ flex: 'none', marginLeft: '25px', justifyContent: 'center' }}
                          >
                            {showPassword ? (
                              <EyeOpen onClick={() => setShowPassword(false)} />
                            ) : (
                              <EyeClosed onClick={() => setShowPassword(true)} />
                            )}
                          </Column>
                        </Row>
                        {errors && errors.password && <Error>{errors.password}</Error>}
                      </Column>
                    </Row>
                    <CountryInputComponent setFieldValue={setFieldValue} />
                    <ButtonRow>
                      <Button type="submit">Entrar</Button>
                    </ButtonRow>
                  </FormWrapper>
                </Row>
              )}
            </Formik>
          </LoginWrapper>
        </>
      )}
      {logos && logos.length > 0 && (
        <LogoWrapper>
          {logos.map(({ id, url }, i) => (
            <Logo key={id} id={id} url={url} first={i === 0} />
          ))}
        </LogoWrapper>
      )}
    </WelcomePage>
  );
};
