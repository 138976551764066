import React from 'react';
import { Row, Column } from '../../../components/Layout';
import { FormSubTitle } from './styled';
import LinkForm from './LinkForm';

export default ({ getData, data }) => (
  <>
    <Row>
      <FormSubTitle>Url de reproduccion actual</FormSubTitle>
    </Row>
    <Row>
      {data.link && (
        <Column>
          <Row style={{ alignItems: 'center' }}>
            <h4>Url actual: </h4>
            <a
              style={{ color: 'aquamarine', marginLeft: '5px' }}
              href={data.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.link}
            </a>
          </Row>
        </Column>
      )}
    </Row>
    {data.docId && (
      <Row>
        <LinkForm docId={data.docId} callback={getData} />
      </Row>
    )}
  </>
);
