import styled, { css } from 'styled-components';
import { DEVICE } from '../../constants';

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const PageCenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isMobile ? 'flex-end' : 'center')};
  align-items: center;
  min-height: 100vh;

  ${({ withBackgroundImage }) =>
    withBackgroundImage &&
    css`
      background-image: url(${withBackgroundImage});
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const WelcomePage = styled(Column)`
  min-height: 100vh;
  max-width: 100vw;
  justify-content: space-around;
  align-items: center;
  overflow-y: scroll;
  ${({ withBackgroundImage }) =>
    withBackgroundImage &&
    css`
      background-image: url(${withBackgroundImage});
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-size: cover;
    `}

  ${({ tiendaDiggitEvent }) =>
    tiendaDiggitEvent &&
    css`
      flex-flow: row wrap;
      justify-content: space-evenly;

      @media ${DEVICE.laptop} {
        background-position: top;
      }
    `}
`;
