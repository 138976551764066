/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { DEVICE } from '../../constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  background-image: ${(props) => props.background && `url(${props.background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${DEVICE.laptopL} {
    background-position: top;
  }
`;

export const Section = styled.div`
  display: flex;
  flex: ${(props) => props && props.flex};
  justify-content: center;

  ${({ multiVideo }) =>
    multiVideo &&
    css`
      flex-direction: column;
    `}

  ${({ player }) =>
    player &&
    css`
      @media ${DEVICE.mobileS} {
        height: 75%;
      }

      @media ${DEVICE.laptopL} {
        height: 70%;
      }
    `}

     ${({ player, hasBanner }) =>
    player &&
    !hasBanner &&
    css`
      @media ${DEVICE.mobileS} {
        height: 90%;
      }

      @media ${DEVICE.laptopL} {
        height: 85%;
      }
    `}
    

  ${({ banner }) =>
    banner &&
    css`
      @media ${DEVICE.mobileS} {
        height: 5%;
      }

      @media ${DEVICE.mobileM} {
        height: 6%;
      }

      @media ${DEVICE.mobileL} {
        height: 9%;
      }

      @media ${DEVICE.tablet} {
        height: 14%;
      }

      @media ${DEVICE.laptop} {
        height: 18%;
      }

      @media ${DEVICE.laptopL} {
        height: 20%;
      }
    `}

    ${({ controls }) =>
    controls &&
    css`
      @media ${DEVICE.tablet} {
        height: 11%;
      }

      @media ${DEVICE.laptop} {
        height: 10%;
      }
    `}
`;

export const CollapsableContent = styled(animated.div)`
  display: flex;
  position: absolute;
  width: auto;
  border: 1px solid lightgray;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.bigStone};
  opacity: 0.9;
  left: 0px;

  @media ${DEVICE.mobileS} {
    height: 60%;
    bottom: 20%;
  }

  @media ${DEVICE.tablet} {
    height: 80%;
    bottom: 10%;
  }
`;

export const CollapsableContentFixed = styled.div`
  display: flex;
  position: absolute;
  width: ${(props) => `${props.width}px`};
  border: 1px solid lightgray;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.bigStone};
  opacity: 0.9;
  right: 0px;

  @media ${DEVICE.mobileS} {
    height: 60%;
    top: 20%;
  }

  @media ${DEVICE.tablet} {
    height: 80%;
    top: 10%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CollapseWrapperFixed = styled.div`
  position: absolute;
  height: 50px;
  width: 50px;
  right: 0px;
  top: 50%;
  margin: 10px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.85;
  transition: 0.4s;
  z-index: 0;

  :hover {
    opacity: 1;
  }

  ${({ showCollapsable }) =>
    showCollapsable &&
    css`
      right: 90px;
      @media ${DEVICE.mobileS} {
        top: 10%;
      }

      @media ${DEVICE.tablet} {
        top: 1%;
      }
    `}

  ${({ showCollapsable }) =>
    !showCollapsable &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

export const CollapseWrapper = styled.div`
  position: absolute;
  height: 50px;
  width: 50px;
  left: 0px;
  top: 50%;
  margin: 10px;
  border-radius: 50%;
  background-color: white;
  opacity: 0.85;
  transition: 0.4s;
  z-index: 0;

  :hover {
    opacity: 1;
  }

  ${({ showCollapsable }) =>
    showCollapsable &&
    css`
      right: 0px;
      left: unset;
    `}

  ${({ showCollapsable }) =>
    !showCollapsable &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;
