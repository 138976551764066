import { createGlobalStyle } from 'styled-components';
import AvantGardeDemi from './AGDemi.ttf';
import AvantGardeBook from './AGGBook.otf';
import AvantGardeBookBold from './AGGDemiBold.otf';

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'AvantGardeDemi';
  src: url(${AvantGardeDemi}) format('truetype')
}

@font-face {
  font-family: 'AvantGardeBook';
  src: url(${AvantGardeBook}) format('opentype')
}

@font-face {
  font-family: 'AvantGardeBookBold';
  src: url(${AvantGardeBookBold}) format('opentype')
}
`;

export default FontStyles;
