import React, { useCallback, useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button';

import { Column, Row } from '../../components/Layout';

import LoadingSpinner from '../../components/LoadingSpinner';
import { COLLECTIONS } from '../../constants';
import ChatSection from '../Panel/TransmisionForm/ChatSection';
import LinkSection from '../Panel/TransmisionForm/LinkSection';
import OnlinesSection from '../Panel/TransmisionForm/OnlinesSection';
import OradoresSection from '../Panel/TransmisionForm/OradoresSection';
import RegistradosSection from '../Panel/TransmisionForm/RegistradosSection';
import { Container } from './styled';

export default () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const firestore = useFirestore();
  const history = useHistory();

  const getData = useCallback(() => {
    firestore
      .collection(COLLECTIONS.STREAMINGS)
      .where('id', '==', id)
      .limit(1)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          const snapshot = doc.data();
          const agenda =
            snapshot.agenda &&
            snapshot.agenda.length > 0 &&
            snapshot.agenda.map((event) => ({
              ...event,
              start: event.start.toDate(),
              end: event.end.toDate()
            }));

          setData({ ...snapshot, docId: doc.id, agenda });
          setLoading(false);
        });
      })
      .catch((e) => {
        console.error(e);
        toast.error('Ocurrio un error');
      });
  }, [id, firestore]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [getData]);

  if (loading) {
    return (
      <Container style={{ justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container>
      <h1>{data.alias.toUpperCase()}</h1>
      <Row>
        <Column>
          <LinkSection data={data} getData={getData} />
          {!data.hideChat && <ChatSection data={data} getData={getData} />}
        </Column>
        <Column>
          <OnlinesSection data={data} getData={getData} />
          <RegistradosSection data={data} />
        </Column>
        <Column>
          <OradoresSection data={data} />
        </Column>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <Button onClick={() => history.goBack()}>Volver</Button>
      </Row>
    </Container>
  );
};
