import React from 'react';

import { Row, Column } from '../../../components/Layout';
import { FormSubTitle } from './styled';
import { ShowColor } from '../styled';
import ColorForm from './ColorForm';

export default ({ data, getData }) => {
  const { colors } = data;
  return (
    <>
      <Row>
        <FormSubTitle>Branding</FormSubTitle>
      </Row>
      <Row>
        <Column>
          {colors && (
            <>
              <h4 style={{ margin: '5px 0px' }}>Colores actuales</h4>
              <Row style={{ marginBottom: '10px' }}>
                <Column>
                  <p>Primario :</p>
                  <ShowColor color={colors.primary || '#F0F'} />
                </Column>
                <Column>
                  <p>Secundario :</p>
                  <ShowColor color={colors.secondary || '#F0F'} />
                </Column>
              </Row>
            </>
          )}
          <ColorForm data={data} callback={getData} />
        </Column>
      </Row>
    </>
  );
};
