import React from 'react';
import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Column, Row } from '../../components/Layout';
import { DEVICE } from '../../constants';

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.89);
  border-radius: 7px;
  z-index: 2;
  margin: 20px 0;

  ${({ loadingFrame }) =>
    loadingFrame &&
    css`
      min-height: 250px;
      justify-content: center;
      align-items: center;
    `}

  ${({ extraMargin }) =>
    extraMargin &&
    css`
      margin-bottom: 60px;
    `}

  @media ${DEVICE.mobileS} {
    width: 250px;
  }

  @media ${DEVICE.mobileL} {
    width: 300px;
  }

  @media ${DEVICE.laptop} {
    width: 500px;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 7px;

  @media ${DEVICE.mobileS} {
    padding: 15px;
  }

  @media ${DEVICE.laptop} {
    padding: 25px;
  }
`;

export const InputContainer = styled(Column)`
  &:hover {
    cursor: pointer;
  }
  @media ${DEVICE.mobileS} {
    margin: 5px 0px;
    width: 100%;
  }

  @media ${DEVICE.tablet} {
    margin: 10px 0px;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: all 0.5s ease-in-out;

  ${InputContainer}:focus {
    top: -14px;
    font-size: 6px;
  }
`;

export const Input = styled.input`
  border: 0;
  border-bottom: 2px solid #555;
  background: transparent;
  width: 100%;
  padding: 8px 0 5px 0;
  font-size: 16px;
  color: #fff;

  &:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid #e74c3c;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
    transition: 'color 9999s ease-out, background-color 9999s ease-out';
    transition-delay: 9999s;
  }

  &::-webkit-input-placeholder {
    color: grey;
  }
`;

export const Button = styled.button`
  width: 192px;
  height: 32px;
  border-radius: 10px;
  font-size: large;
  color: white;
  background-color: rgb(0, 155, 221);
  letter-spacing: 1px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Error = styled.p`
  font-size: 12px;
  color: red;
  text-align: end;
`;

const LogoImage = styled(LazyLoadImage)`
  display: block;
  width: auto;
  height: auto;

  @media ${DEVICE.mobileS} {
    max-width: 250px;
  }

  @media ${DEVICE.mobileL} {
    max-width: 300px;
  }

  @media ${DEVICE.laptop} {
    max-width: 500px;
  }
`;

export const Logo = ({ id, last, url }) => (
  <LogoImage last={last} src={url} alt={`logo${id}`} effect="blur" />
);

export const BottomLogo = ({ url, pullRight, first, second }) => (
  <RatioImage src={url} effect="blur" pullRight={pullRight} first={first} second={second} />
);

export const CalendarLogo = styled.img`
  display: block;
  width: auto;
  height: auto;

  @media ${DEVICE.mobileS} {
    max-width: 100px;
  }
`;

export const RatioImage = styled.img`
  display: block;
  max-height: 32px;
  width: auto;
  height: auto;
  margin-right: 10px;
  margin-left: ${(props) => props && props.pullRight && 'auto'};
  margin-top: 0.5rem;

  @media ${DEVICE.mobileS} {
    margin-left: initial;
  }

  @media ${DEVICE.laptop} {
    margin-left: ${(props) => props && props.pullRight && 'auto'};
  }
`;

export const BottomLogos = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;

  @media ${DEVICE.mobileS} {
    width: 90%;
  }

  @media ${DEVICE.laptop} {
    width: 60%;
  }
`;

export const LogoWrapper = styled(Row)`
  display: flex;
  flex-wrap: wrap;

  @media ${DEVICE.mobileS} {
    width: 250px;
  }

  @media ${DEVICE.mobileL} {
    width: 300px;
  }

  @media ${DEVICE.laptop} {
    width: 550px;
  }
`;
export const FooterLogos = styled.img`
  margin: 3rem 0;

  @media ${DEVICE.mobileS} {
    width: 100%;
  }
`;

export const GermanyWrapper = styled(Row)`
  margin: 8rem;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);

  @media ${DEVICE.mobileS} {
    padding: 0;
    margin: 4rem;
  }
`;

export const CountdownWrapper = styled(Column)`
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  align-items: center;

  ${({ nobackground }) =>
    nobackground &&
    css`
      background-color: transparent;
    `}

  @media ${DEVICE.mobileS} {
    width: 100%;
  }

  @media ${DEVICE.tablet} {
    width: 80%;
  }
`;

export const ButtonRow = styled(Row)`
  justify-content: center;

  @media ${DEVICE.mobileS} {
    margin-top: 15px;
  }

  @media ${DEVICE.laptop} {
    margin-top: 20px;
  }
`;

export const ThanksRegisterMessage = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  color: white;
  text-align: center;
  margin-top: 15px;
  background: black;
  opacity: 0.8;
  margin: 0;

  & > div {
    @media ${DEVICE.mobileS} {
      width: 250px;
      font-size: large;
    }

    @media ${DEVICE.mobileL} {
      width: 300px;
      font-size: large;
    }

    @media ${DEVICE.laptop} {
      width: 500px;
      font-size: xxx-large;
    }
  }
`;

export const PreRegisterTitle = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.mobileS} {
    width: 250px;
  }

  @media ${DEVICE.mobileL} {
    width: 300px;
  }

  @media ${DEVICE.laptop} {
    width: 500px;
  }

  :first-child {
    color: white;

    @media ${DEVICE.mobileS} {
      font-size: medium;
    }

    @media ${DEVICE.laptop} {
      font-size: xx-large;
    }
  }

  div:nth-child(2) {
    color: yellow;

    @media ${DEVICE.mobileS} {
      font-size: x-large;
    }

    @media ${DEVICE.laptop} {
      font-size: xx-large;
    }
  }
`;

export const RegisterButton = styled.img`
  border-radius: 7px;
  @media ${DEVICE.mobileS} {
    width: 128px;
  }

  @media ${DEVICE.laptop} {
    width: 192px;
  }
`;

export const StyledRow = styled(Row)`
  justify-content: center;
  align-items: center;

  ${({ title }) =>
    title &&
    css`
      color: #f0bd31;
      font-weight: bolder;

      @media ${DEVICE.mobileS} {
        font-size: 1rem;
      }

      @media ${DEVICE.tablet} {
        font-size: 2rem;
      }
    `}

  ${({ value }) =>
    value &&
    css`
      color: white;
      font-weight: bolder;

      @media ${DEVICE.mobileS} {
        font-size: 3rem;
      }

      @media ${DEVICE.tablet} {
        font-size: 5rem;
      }

      @media ${DEVICE.laptop} {
        font-size: 8rem;
      }
    `}
`;

export const Content = styled.img`
  max-width: 70%;
  @media ${DEVICE.mobileS} {
    margin: 2.5rem 0;
  }

  @media ${DEVICE.tablet} {
    margin: 4rem 0;
  }

  ${({ title }) =>
    title &&
    css`
      display: block;
      width: auto;
      height: auto;
    `}

  ${({ ingresar }) =>
    ingresar &&
    css`
      @media ${DEVICE.mobileS} {
        max-width: 100%;
      }

      @media ${DEVICE.tablet} {
        width: 256px;
      }
    `}

     ${({ agenda }) =>
    agenda &&
    css`
      max-width: 100%;
    `}
`;
