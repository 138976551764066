import React from 'react';
import moment from 'moment';
import { Row, Column } from '../../Layout';
import { Container, Content, TimeWrapper } from './styled';

export default ({ message, self, event }) => {
  const { name, lastName, userName, created, text, fullName } = message;
  const time = moment(created).format('hh:mm A');
  const nameToShow = fullName || userName || `${name} ${lastName}`;
  return (
    <Container self={self} event={event}>
      <Content self={self}>
        <Column>
          <Row style={{ marginBottom: '5px', fontSize: '10px' }}>
            {self ? 'Tú' : `${nameToShow}`}
          </Row>
          <Row>
            <Column style={{ flex: 2 }}>
              <Row style={{ paddingLeft: '5px', wordBreak: 'break-word' }}>{text}</Row>
            </Column>
            <TimeWrapper>
              <Row style={{ justifyContent: 'flex-end' }}>{time}</Row>
            </TimeWrapper>
          </Row>
        </Column>
      </Content>
    </Container>
  );
};
