/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { Button } from './PreRegister/styled';

export const Container = styled.div`
  width: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
`;

export const Logo = styled.img`
  width: 6vw;
  object-fit: cover;

  ${({ isNotBigScreen }) =>
    isNotBigScreen &&
    css`
      width: 10vmax;
    `}

  ${({ flag }) =>
    flag &&
    css`
      margin: 2rem 0;
      width: 3vw;

      ${({ isNotBigScreen }) =>
        isNotBigScreen &&
        css`
          width: 10vw;
        `}
    `}

  ${({ streaming }) =>
    streaming &&
    css`
      width: 3vmax;
      margin: 0 2vmin;
      object-fit: contain;
    `}

    ${({ library }) =>
    library &&
    css`
      width: 4vmax;
    `}
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.mulberry};
  color: ${(props) => props.theme.colors.white};
  border: none;
  cursor: pointer;
  transition: all 0.5s linear;
  width: 10vmax;
  min-height: 2vmax;
  font-size: ${(props) => props.theme.fontSize.small};

  &:hover {
    transform: scale(1.1);
  }

  ${({ isNotBigScreen }) =>
    isNotBigScreen &&
    css`
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.secondaryColor};
    `}
`;
