import { useEffect, useState } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { COLLECTIONS } from '../constants';
import useUrlAlias from './useUrlAlias';

export default (alias) => {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(true);
  const [background, setBackground] = useState(null);
  const aliasToUse = alias || useUrlAlias();

  useEffect(() => {
    firestore
      .collection(COLLECTIONS.BACKGROUNDS)
      .where('alias', '==', aliasToUse)
      .get()
      .then((result) => {
        result.forEach((doc) => {
          const data = doc.data();
          setBackground(data);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [firestore, aliasToUse]);

  return { loadingBackground: loading, background };
};
