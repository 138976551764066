import styled, { css } from 'styled-components';
import { Column, Row } from '../../components/Layout';

export const Container = styled(Column)`
  min-height: 100vh;
  margin: 20px;

  ${({ background }) =>
    background &&
    css`
      background-image: url(${background});
      background-position: center;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const Section = styled(Row)`
  padding-top: ${(props) => props.withPadding && '15px'};
  border-bottom: ${(props) => props.withBorder && '1px solid whitesmoke'};
`;
